<template>
	<img
		v-if="!typeIsMobile"
		class="pc_imgback"
		src="../../assets/images/web/new_activity.png"
		alt=""
	/>

	<img v-else class="pc_imgback" src="../../assets/images/web/activity_pic-bg.png" />

	<div class="center_content_plate"></div>

	<div class="pc_center_plate">
		<p
			style="color: #ffffff; font-weight: bold"
			:style="{
				fontSize: !typeIsMobile ? '24px' : '20px',
				marginBottom: !typeIsMobile ? '8px' : '16px',
			}"
		>
			建廟沿革
		</p>

		<div class="center_book_plate center_flex_layout">
			<flipbook
				class="flipbook"
				:clickToZoom="false"
				forwardDirection="left"
				:startPage="currentPageNum"
				@flip-left-end="showPageContent"
				@flip-right-end="showPageContent"
				:nPolygons="120"
				:gloss="0.2"
				ref="flipbook_page"
				v-slot="flipbook_page"
				:pages="pageImages"
			>
				<div class="bookright_button_plate">
					<div
						class="center_flex_layout"
						style="flex-direction: column; height: 498px; justify-content: space-between"
					>
						<div>
							<p
								v-for="(sortName, index) in sortArr"
								:key="{ index }"
								class="sort_button_plate center_flex_layout hover_style"
								@click="checkPartPage(sortName)"
								:class="checkedName === sortName ? 'selected_sort_button' : ''"
							>
								{{ sortName }}
							</p>
						</div>

						<div>
							<p
								v-for="(sortName, index) in fontSizeArr"
								:key="{ index }"
								class="sort_button_plate center_flex_layout hover_style"
								@click="checkSizeFn(sortName)"
								:class="checkedFontSize === sortName ? 'selected_sort_button' : ''"
							>
								{{ sortName }}
							</p>
						</div>
					</div>
				</div>

				<div class="progress">
					<el-progress
						:percentage="Number(flipbook_page.page / flipbook_page.numPages) * 100"
						:show-text="false"
						color="#474693"
					/>
				</div>
			</flipbook>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
const { proxy } = getCurrentInstance();

import Flipbook from "flipbook-vue";

const typeIsMobile = ref(false);
const pageImages = ref([]);
const sortArr = ref(["壹", "貳", "參"]);
const checkedName = ref("");
const currentPageNum = ref(1);
const fontSizeArr = ref(["大", "小"]);
const checkedFontSize = ref("小");

onMounted(() => {
	typeIsMobile.value = proxy.$mobile() ? true : false;

	pushImages();
});

const pushImages = () => {
	let list = [
		null,
		require("../../assets/images/web/pcbigbook/post.jpg"),
		require("../../assets/images/web/pcbigbook/empty_page_image.jpg"),
	];

	if (checkedFontSize.value === "大") {
		for (let i = 1; i <= 41; i++) {
			list.push(require(`../../assets/images/web/pcbigbook/${Number(i)}.jpg`));
			pageImages.value = list;
		}
	} else {
		for (let i = 1; i <= 20; i++) {
			list.push(require(`../../assets/images/web/pcsmallbook/${Number(i)}.jpg`));
			pageImages.value = list;
		}
	}

	pageImages.value.push(require("../../assets/images/web/pcbigbook/empty_page_image.jpg"));
};

const checkPartPage = (name) => {
	if (checkedName.value !== name) {
		checkedName.value = name;
		if (name === "貳") {
			currentPageNum.value = checkedFontSize.value === "大" ? 18 : 10;
		} else if (name === "參") {
			currentPageNum.value = checkedFontSize.value === "大" ? 42 : 20;
		} else {
			currentPageNum.value = 2;
		}
	}
};

const checkSizeFn = (name) => {
	if (checkedFontSize.value !== name) {
		checkedFontSize.value = name;
		currentPageNum.value = 0;
		pageImages.value = [];
		pushImages();

		setTimeout(() => {
			currentPageNum.value = 1;
			checkedName.value = "";
		}, 50);
	}
};

const showPageContent = () => {
	// console.log(proxy.$refs.flipbook_page.page);
	let _pageNum = proxy.$refs.flipbook_page.page;

	if (checkedFontSize.value === "大") {
		if (_pageNum >= 2 && _pageNum < 18) {
			checkedName.value = "壹";
		} else if (_pageNum >= 18 && _pageNum < 42) {
			checkedName.value = "貳";
		} else if (_pageNum >= 42) {
			checkedName.value = "參";
		} else {
			checkedName.value = "";
		}
	} else {
		if (_pageNum >= 2 && _pageNum < 10) {
			checkedName.value = "壹";
		} else if (_pageNum >= 10 && _pageNum < 20) {
			checkedName.value = "貳";
		} else if (_pageNum >= 20) {
			checkedName.value = "參";
		} else {
			checkedName.value = "";
		}
	}
};
</script>

<style lang="less" scoped>
.pc_imgback {
	width: 100vw;
	position: fixed;
	bottom: 0;

	@media screen and (max-width: 500px) {
		bottom: 70px;
	}
}

.center_content_plate {
	width: 100%;
	height: 100%;
	background: linear-gradient(to bottom, #b47e31 8%, #ffbe4e 28%, #ffefc7 70%);
	position: fixed;
	top: 0;
	opacity: 0.7;
}

.pc_center_plate {
	min-height: calc(100vh - 264px);
	margin: 0 59px;
	margin-top: 42px;
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;

	@media screen and (max-width: 500px) {
		min-height: calc(100vh - 326px);
		margin: 0 24px;
	}

	.center_book_plate {
		// flex: 1;
		padding-top: 16px;
		padding-bottom: 32px;
		border-radius: 8px;
		box-sizing: border-box;
		backdrop-filter: blur(10px);
		background: rgba(183, 128, 50, 0.3);
		margin-bottom: 30px;

		.sort_button_plate {
			width: 40px;
			height: 40px;
			border-radius: 4px;
			background-color: #ffffff;
			font-size: 20px;
			color: #474693;
			margin-bottom: 8px;
			font-weight: bolder;
		}

		.selected_sort_button {
			background-color: #474693 !important;
			color: #b4b3e4 !important;
		}
	}
}

.flipbook {
	width: 702px;
	height: 498px;
	position: relative;

	@media screen and (max-width: 500px) {
		width: 297px;
		height: 424px;
	}

	.bookright_button_plate {
		width: 40px;
		height: 498px;
		position: absolute;
		right: -56px;
		z-index: 2;
	}
}

.progress {
	position: absolute;
	z-index: 10;
	width: 398px;
	left: 152px;
	bottom: -16px;
}
</style>
