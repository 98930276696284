<template>
	<div style="background-color: #fffbef">
		<div style="position: relative; min-height: 600px">
			<img class="home_top_backimg" src="../../assets/images/web/home_top_img.png" />

			<div class="web_main_plate">
				<div
					class="center_flex_layout"
					style="justify-content: space-between; align-items: flex-start"
				>
					<div
						class="left_activity_part center_flex_layout"
						style="justify-content: flex-start; align-items: flex-start"
					>
						<div>
							<div
								class="center_flex_layout hover_style"
								v-for="(leftTitle, index) in leftBigTitle"
								:key="{ index }"
								:style="{ marginTop: index === 0 ? '10px' : '15px' }"
								@click="leftAction(leftTitle.type)"
							>
								<img
									style="width: 24px; height: 24px; margin-left: 8px"
									:style="{ opacity: leftTitleIndex === leftTitle.type ? 1 : 0 }"
									src="../../assets/images/web/arrow-right-thick.png"
								/>

								<p
									style="
										font-size: 20px;
										font-weight: bolder;
										color: #ffffff;
										margin-right: 16px;
										white-space: nowrap;
									"
								>
									{{ leftTitle.name }}
								</p>
							</div>
						</div>

						<div class="activity_title_plate">
							<div
								v-for="(newTitle, index) in newTitleArr"
								:key="{ index }"
								class="activity_font_back center_flex_layout"
								style="justify-content: flex-start; align-items: flex-start; cursor: pointer"
								:style="{ marginTop: index === 0 ? 0 : '6px' }"
								@click="detail(newTitle.id)"
							>
								<div class="new_label center_flex_layout">
									<p class="new_label_font" style="font-family: 'Avenir'">NEW</p>
								</div>
								<span class="activity_title"
									>{{ newTitle.month }}&nbsp;{{ newTitle.date }}
									<span style="letter-spacing: 1px">
										{{ newTitle.applyName }}
									</span>
								</span>
							</div>

							<div
								v-for="(dateTitle, index) in dateTitleArr"
								:key="{ index }"
								class="center_flex_layout"
								style="
									justify-content: flex-start;
									margin-top: 6px;
									cursor: pointer;
									align-items: flex-start;
								"
								@click="detail(dateTitle.id)"
							>
								<p
									class="activity_font_back activity_title one_text_hidden"
									style="color: #eaa334; margin-top: 0; margin-left: 0; min-width: 156px"
								>
									{{ dateTitle.month }}&nbsp;{{ dateTitle.date }}
								</p>
								<p
									class="activity_title"
									style="color: #ffffff; letter-spacing: 1px; min-width: 156px"
								>
									{{ dateTitle.applyName }}
								</p>
							</div>
						</div>
					</div>

					<div>
						<div>
							<img
								style="width: 282px; height: 312px"
								src="../../assets/images/web/home_top_text_img.png"
							/>
						</div>
						<div
							v-for="(imageType, index) in rightTypeArr"
							:key="{ index }"
							class="center_flex_layout hover_style"
							style="justify-content: flex-end; margin-right: 9px"
							:style="{ marginTop: index === 0 ? '38px' : '8px' }"
							@click="trafficPage(imageType.type)"
						>
							<img style="width: 184px; height: 32px" :src="imageType.url" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<!--建廟沿革-->
		<div style="margin: 0 59px">
			<div class="center_flex_layout" style="margin-top: 87px">
				<div style="position: relative">
					<p class="part_title">建廟沿革</p>

					<img class="title_image" src="../../assets/images/web/xiangyun_image.png" />
				</div>
			</div>
			<div class="center_flex_layout">
				<p style="font-size: 18px; color: #474693; font-family: NotoSerifTC-Medium">
					我們的歷史與故事
				</p>
			</div>

			<div
				class="center_flex_layout"
				style="justify-content: space-between; margin-top: 32px; position: relative"
			>
				<div>
					<p style="font-size: 24px; color: #2b2b2b">
						壹 | <span style="font-size: 16px">開基佛祖 - 與佛結緣</span>
					</p>

					<p
						style="
							font-size: 14px;
							color: #707070;
							margin-top: 8px;
							font-family: NotoSerifTC-Regular;
							line-height: 26px;
						"
					>
						壬戌年，某日，炙熱的陽光，照映進壓著海棠花的懷舊窗櫺上。兩鬢霜白、白髮銀須、臉龐睿智的佛具店老闆，齊老，正在日常的整理著店舖內的佛具，店內泛黃老舊的牆壁上，掛著清末時期御賜的匾額，光滑的磨石子地板上鋪著淺淺一層檀香粉，透亮著來回踱步的腳印。
						忽然有一群善男信女走進了店裡，齊老開口詢問：「各位師兄師姐好！有特別想找什麼嗎？」......
					</p>

					<div class="center_flex_layout" style="justify-content: flex-start">
						<p
							class="click_view_total hover_style"
							@click="clickViewAll('all')"
							style="font-family: NotoSerifTC-Medium"
						>
							點我看全文
						</p>
					</div>
				</div>

				<img
					style="width: 55vw; margin-left: 44px"
					src="../../assets/images/web/jianmiao_image.png"
				/>

				<img class="left_little_backimg" src="../../assets/images/web/pc-golden-mountain.png" />
			</div>

			<div class="center_flex_layout" style="margin-top: 133px">
				<div style="position: relative">
					<p class="part_title">師父來惹</p>

					<img class="title_image" src="../../assets/images/web/xiangyun_image.png" />
				</div>
			</div>
			<div class="center_flex_layout">
				<p style="font-size: 18px; color: #474693; font-family: NotoSerifTC-Medium">文冊系列</p>
			</div>

			<el-carousel
				ref="carouselImageOne"
				indicator-position="none"
				height="auto"
				:loop="false"
				:autoplay="false"
				arrow="always"
				:initial-index="imagesOneIndex"
				@change="carouselOneChange"
				style="margin-top: 32px"
				v-if="imagesArrOne.length"
			>
				<div class="swiper_icon_plate hover_style" @click="changeImageOne('prev')" style="left: 0">
					<img style="width: 100%; height: 100%" src="../../assets/images/web/swiper_icon.png" />
				</div>

				<div
					class="swiper_icon_plate hover_style"
					@click="changeImageOne('next')"
					style="right: 0; transform: rotate(180deg)"
				>
					<img style="width: 100%; height: 100%" src="../../assets/images/web/swiper_icon.png" />
				</div>

				<el-carousel-item
					v-for="(itemImages, index) in imagesArrOne"
					:key="index"
					style="display: flex; height: max-content"
					class="center_flex_layout"
				>
					<div class="center_flex_layout" style="width: 70vw; height: 100%; flex-flow: row wrap">
						<img
							v-for="(imageData, imageIndex) in itemImages"
							:key="imageIndex"
							class="four_part_img hover_style"
							@click="clickViewAll('come', imageData)"
							:src="require('../../assets/images/web/wence/' + imageData.url)"
						/>
					</div>
				</el-carousel-item>
			</el-carousel>

			<div class="center_flex_layout" style="margin-top: 133px">
				<div style="position: relative">
					<p class="part_title">師父來惹</p>

					<img class="title_image" src="../../assets/images/web/xiangyun_image.png" />
				</div>
			</div>
			<div class="center_flex_layout">
				<p style="font-size: 18px; color: #474693; font-family: NotoSerifTC-Medium">師父寓也</p>
			</div>

			<el-carousel
				ref="carouselImageTwo"
				indicator-position="none"
				height="auto"
				:loop="false"
				:autoplay="false"
				arrow="always"
				:initial-index="imagesTwoIndex"
				@change="carouselTwoChange"
				style="margin-top: 32px"
				v-if="imagesArrTwo.length"
			>
				<div class="swiper_icon_plate hover_style" @click="changeImageTwo('prev')" style="left: 0">
					<img style="width: 100%; height: 100%" src="../../assets/images/web/swiper_icon.png" />
				</div>

				<div
					class="swiper_icon_plate hover_style"
					@click="changeImageTwo('next')"
					style="right: 0; transform: rotate(180deg)"
				>
					<img style="width: 100%; height: 100%" src="../../assets/images/web/swiper_icon.png" />
				</div>

				<el-carousel-item
					v-for="(itemImages, index) in imagesArrTwo"
					:key="index"
					style="display: flex; height: max-content"
					class="center_flex_layout"
				>
					<div class="center_flex_layout" style="width: 70vw; height: 100%; flex-flow: row wrap">
						<img
							v-for="(imageData, imageIndex) in itemImages"
							:key="imageIndex"
							class="four_part_img hover_style"
							@click="clickViewAll('yuye', imageData)"
							:src="require('../../assets/images/web/yuye/' + imageData.url)"
						/>
					</div>
				</el-carousel-item>
			</el-carousel>

			<div style="position: relative">
				<img class="activity_start_img" src="../../assets/images/web/mountain.png" />

				<div class="center_flex_layout" style="margin-top: 133px">
					<div style="position: relative">
						<p class="part_title">活動花絮</p>

						<img class="title_image" src="../../assets/images/web/xiangyun_image.png" />
					</div>
				</div>
				<div class="center_flex_layout">
					<p style="font-size: 18px; color: #474693; font-family: NotoSerifTC-Medium">回顧相冊</p>
				</div>

				<el-carousel
					ref="carouselImageThree"
					indicator-position="none"
					height="auto"
					:loop="false"
					:autoplay="false"
					arrow="always"
					:initial-index="imagesThreeIndex"
					@change="carouselThreeChange"
					style="margin-top: 32px"
					v-if="imagesArrThree.length"
				>
					<div
						class="swiper_icon_plate hover_style"
						@click="changeImageThree('prev')"
						style="left: 0"
					>
						<img style="width: 100%; height: 100%" src="../../assets/images/web/swiper_icon.png" />
					</div>

					<div
						class="swiper_icon_plate hover_style"
						@click="changeImageThree('next')"
						style="right: 0; transform: rotate(180deg)"
					>
						<img style="width: 100%; height: 100%" src="../../assets/images/web/swiper_icon.png" />
					</div>

					<el-carousel-item
						v-for="(itemImages, index) in imagesArrThree"
						:key="index"
						style="display: flex; height: max-content"
						class="center_flex_layout"
					>
						<div style="position: relative">
							<img style="width: 70vw; aspect-ratio: 1 / 0.58" :src="itemImages.url" />

							<div class="banner_pop_plate center_flex_layout">
								<div style="margin: 0 48px">
									<div class="center_flex_layout" style="justify-content: flex-start">
										<p class="activity_year_font">{{ itemImages.year }}</p>
									</div>

									<p class="activity_content_font" style="font-size: 32px; font-weight: bolder">
										{{ itemImages.title }}
									</p>

									<p
										v-if="itemImages.content"
										class="activity_content_font"
										style="margin-top: 8px; line-height: 18px; letter-spacing: 0.5px"
									>
										{{ itemImages.content }}
									</p>

									<p
										v-if="itemImages.contentLine"
										class="activity_content_font"
										style="margin-top: 30px; line-height: 18px; letter-spacing: 0.5px"
									>
										{{ itemImages.contentLine }}
									</p>

									<div class="center_flex_layout" style="justify-content: flex-start">
										<p
											class="view_activity_button hover_style"
											@click="viewActivityImages(itemImages.params)"
											style="font-family: NotoSerifTC-Medium"
										>
											查看活動花絮相簿
										</p>
									</div>
								</div>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>

			<div class="center_flex_layout" style="margin-top: 133px">
				<div style="position: relative">
					<p class="part_title">服務項目</p>

					<img class="title_image" src="../../assets/images/web/xiangyun_image.png" />
				</div>
			</div>
			<div class="center_flex_layout">
				<p style="font-size: 18px; color: #474693; font-family: NotoSerifTC-Medium">
					祈求平安改善運勢
				</p>
			</div>

			<div class="center_flex_layout" style="padding-bottom: 104px">
				<div class="center_flex_layout" style="flex-flow: row wrap; width: 1114px">
					<div
						v-for="(itemImages, index) in serviceImages"
						:key="{ index }"
						class="service_item_image hover_style"
						@click="serviceItemPage"
					>
						<img style="width: 100%; height: 100%" :src="itemImages.url" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
const { proxy } = getCurrentInstance();
const router = useRouter();
import axios from "axios";
import { get, post } from "../../utils/request";

const leftBigTitle = ref([
	{ name: "近期活動", type: 1 },
	{ name: "即將舉辦", type: 2 },
]);
const newTitleArr = ref([]);
const newTitleArr1 = ref([
	{ data: "新地簡介《暗夜中的善行，悄然點亮心靈》", id: "home" },
	{ data: "十一月 2023-11-01~02《觀世音菩薩出家紀念日》", id: "39" },
]);
const dateTitleArr = ref([]);
const dateTitleArr1 = ref([
	{ date: "十一月 2023-11-02", title: "《觀世音菩薩出家紀念日》", id: "39" },
	{ date: "十一月 2023-11-27", title: "《下元水官大帝聖誕》", id: "40" },
	{ date: "十一月 2023-11-30", title: "《地母至尊娘娘聖誕》", id: "41" },
]);
const leftTitleIndex = ref(1);
const rightTypeArr = ref([
	{ url: require("../../assets/images/web/btn-youtube.png"), type: "youtube" },
	{ url: require("../../assets/images/web/btn-fb.png"), type: "facebook" },
	{ url: require("../../assets/images/web/btn-ig.png"), type: "ins" },
	{ url: require("../../assets/images/web/btn-traffic.png"), type: "traffic" },
]);
const imagesOneIndex = ref(0);
const imagesArrOne = ref([]);
const imagesTwoIndex = ref(0);
const imagesArrTwo = ref([]);
const imagesThreeIndex = ref(0);
const imagesArrThree = ref([
	{
		url: require("../../assets/images/web/activity_banner.png"),
		year: "2023",
		title: "清願普渡法會",
		content:
			"清願普渡法會是一個充滿慈悲和善意的重要活動。在這個儀式中，我們以虔誠的心情追憶先人，並祈禱他們在來世得到安寧與智慧。普渡法會的核心價值是慈悲。我們以慈悲的心情面對生命的無常，對逝去的親人和眾生抱持著無私的關懷和愛心。透過法會的舉行，我們希望能為亡者獲得解脫，也帶給自己更深層次的心靈寄託。",
		contentLine:
			"讓我們以慈悲和善意為引領，參與普渡法會，心靈得到淨化與滋養。這是一個機會，讓我們回歸內心的平靜，並向眾生散播慈悲和善意的種子。願這份慈悲和善意持續傳承下去，為所有眾生的福祉和和平作出貢獻。",
		params: "/outakesDetail?id=1&type=0",
	},
	// {
	// 	url: require("../../assets/images/web/activity_banner_04.png"),
	// 	year: "2023",
	// 	title: "清明法會",
	// 	content: "",
	// 	contentLine: "",
	// 	params: "/outakesDetail?id=1&type=0",
	// },
	{
		url: require("../../assets/images/web/activity_banner_03.png"),
		year: "2023",
		title: "大年初四",
		content: "",
		contentLine: "",
		params: "/outakesDetail?id=2&type=0",
	},
	{
		url: require("../../assets/images/web/activity_banner_02.png"),
		year: "2022",
		title: "師父來惹 座談會",
		content: "",
		contentLine: "",
		params: "/outakesDetail?id=1&type=1",
	},
	{
		url: require("../../assets/images/web/activity_banner_01.png"),
		year: "2023",
		title: "大南巡",
		content: "",
		contentLine: "",
		params: "/outakesDetail?id=2&type=1",
	},
]);
const serviceImages = ref([
	{ url: require("../../assets/images/web/diandeng_item_image.png") },
	{ url: require("../../assets/images/web/jigai_item_image.png") },
	{ url: require("../../assets/images/web/jishiwenshi_item_image.png") },
	{ url: require("../../assets/images/web/jingche_item_image.png") },
	{ url: require("../../assets/images/web/buyun_item_image.png") },
	{ url: require("../../assets/images/web/yinguo_item_image.png") },
]);
const scrollNumVal = ref(0);
//首頁活動詳情跳轉
const detailList = ref([]);
const detail = (id) => {
	console.log(id);
	router.push(`/NewEvents?id=${id}`);
};
onMounted(() => {
	getShifuCome();
	getShifuYuye();
	getActivityList();
});

const getActivityList = () => {
	get("/web/apply/home/page?type=" + leftTitleIndex.value)
		.then((res) => {
			// console.log(JSON.stringify(res));
			if (res.code === 1) {
				let resultList = res.result;
				dateTitleArr.value = resultList.filter((item) => {
					return item.newLogo === 0;
				});
				newTitleArr.value = resultList.filter((item) => {
					return item.newLogo === 1;
				});
			}
		})
		.catch((error) => {
			console.log(error);
		});
};

const leftAction = (index) => {
	leftTitleIndex.value = index;
	getActivityList();
};

const changeImageOne = (type) => {
	if (type === "next") {
		proxy.$refs.carouselImageOne.next();
	} else {
		proxy.$refs.carouselImageOne.prev();
	}
};

const changeImageTwo = (type) => {
	if (type === "next") {
		proxy.$refs.carouselImageTwo.next();
	} else {
		proxy.$refs.carouselImageTwo.prev();
	}
};

const changeImageThree = (type) => {
	if (type === "next") {
		proxy.$refs.carouselImageThree.next();
	} else {
		proxy.$refs.carouselImageThree.prev();
	}
};

const trafficPage = (btnType) => {
	if (btnType === "youtube") {
		window.open("https://www.youtube.com/@user-lw3df6ez4t/streams");
	} else if (btnType === "facebook") {
		window.open("https://www.facebook.com/translator0202");
	} else if (btnType === "ins") {
		window.open("https://www.instagram.com/official.shifu/");
	} else {
		router.push("/mapAddress");
	}
};

const clickViewAll = (viewType, item) => {
	if (viewType === "all") {
		router.push("/jianMiao");
	} else if (viewType === "come") {
		window.open(item.jumpurl);
		// router.push("/shifuCome");
	} else if (viewType === "yuye") {
		window.open(item.jumpurl);
		// router.push("/shifuCome?name=yuye");
	}
	scrollNum();
};

const serviceItemPage = () => {
	router.push("/serviceProject");
	scrollNum();
};

const resetUrl = axios.create({
	baseURL: "",
});

const getShifuCome = () => {
	resetUrl({
		method: "GET",
		url: "/shifulaile_wence.json",
	}).then((res) => {
		// console.log(JSON.stringify(res.data.data));
		let imagesArray = new Array();
		let bigImagesArray = new Array();
		imagesArray = res.data.data;
		let currentList = [];
		imagesArray.forEach((item, index) => {
			currentList.push({
				url: item.url,
				jumpurl: item.jumpurl,
			});
			if (index === imagesArray.length - 1 || (index + 1) % 4 == 0) {
				bigImagesArray.push(currentList);
				currentList = [];
			}
		});
		imagesArrOne.value = bigImagesArray;
	});
};

const getShifuYuye = () => {
	resetUrl({
		method: "GET",
		url: "/shifulaile_yuye.json",
	}).then((res) => {
		// console.log(JSON.stringify(res.data.data));
		let imagesArray = new Array();
		let bigImagesArray = new Array();
		imagesArray = res.data.data;
		let currentList = [];
		imagesArray.forEach((item, index) => {
			currentList.push({
				url: item.url,
				jumpurl: item.jumpurl,
			});
			if (index === imagesArray.length - 1 || (index + 1) % 4 == 0) {
				bigImagesArray.push(currentList);
				currentList = [];
			}
		});
		imagesArrTwo.value = bigImagesArray;
	});
};

const viewActivityImages = (value) => {
	router.push(value);
	scrollNum();
};

const carouselOneChange = (data) => {
	imagesOneIndex.value = data;
};

const carouselTwoChange = (data) => {
	imagesTwoIndex.value = data;
};

const carouselThreeChange = (data) => {
	imagesThreeIndex.value = data;
};

const scrollNum = () => {
	let getScrollVal = document.getElementsByClassName("global_back")[0].scrollTop;
	scrollNumVal.value = getScrollVal;
};

watch(
	() => router.currentRoute.value,
	(newValue) => {
		// console.log(newValue.name);
		setTimeout(() => {
			if (scrollNumVal.value) {
				proxy.$refs.carouselImageOne.setActiveItem(imagesOneIndex.value);
				proxy.$refs.carouselImageTwo.setActiveItem(imagesTwoIndex.value);
				proxy.$refs.carouselImageThree.setActiveItem(imagesThreeIndex.value);
			}
			let scrollObject = document.getElementsByClassName("global_back")[0];
			scrollObject.scrollTo({ top: scrollNumVal.value, behavior: "instant" });
		}, 50);
	},
	{ immediate: true }
);
</script>

<style lang="less" scoped>
.home_top_backimg {
	width: 100%;
	margin-top: -102px;
}

.web_main_plate {
	width: calc(100% - 118px);
	position: absolute;
	top: 0;
	left: 59px;
}

.left_activity_part {
	margin-top: 60px;

	.activity_title_plate {
		padding-top: 10px;
		padding-left: 16px;
		position: relative;

		&:before {
			content: "";
			width: 2px;
			height: 100%;
			background-color: #ffffff;
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	.new_label {
		background-color: #6e94c3;
		border-radius: 4px;
		margin-top: 4px;
	}

	.new_label_font {
		font-size: 12px;
		color: #ffffff;
		// font-weight: bold;
		transform: scale(0.8);
	}

	.activity_title {
		font-size: 16px;
		color: #6e94c3;
		// font-weight: bold;
		margin-left: 4px;
	}

	.activity_font_back {
		padding: 5px 10px;
		background-color: #ffffff;
		border-radius: 8px;
		margin-top: 6px;
	}
}

.click_view_total {
	font-size: 16px;
	color: #474693;
	margin-top: 24px;
	text-decoration: underline;
	position: relative;
	z-index: 2;
	font-family: "Avenir";
}

.left_little_backimg {
	width: 530px;
	height: 308px;
	position: absolute;
	bottom: -133px;
	left: -59px;
}

.part_title {
	font-size: 30px;
	color: #474693;
	// font-weight: bolder;
	position: relative;
	z-index: 2;
}

.title_image {
	width: 112px;
	height: 66px;
	position: absolute;
	top: -15px;
	left: -66px;
}

.swiper_icon_plate {
	width: 80px;
	height: 80px;
	position: absolute;
	top: calc(50% - 40px);
	z-index: 99;

	@media screen and (max-width: 1200px) {
		width: 60px;
		height: 60px;
	}
}

.service_item_image {
	width: 294px;
	margin: 0 38px;
	margin-top: 32px;
}

.activity_start_img {
	width: 1073px;
	position: absolute;
	top: -55px;
	right: -59px;
	opacity: 0.4;
}

.four_part_img {
	width: calc((100% - 26px) / 2);
	aspect-ratio: 1 / 0.58;
	// height: calc((100% - 24px) / 2);
	margin-left: 26px;
	margin-bottom: 24px;

	&:nth-child(2n -1) {
		margin-left: 0;
	}
}

.banner_pop_plate {
	width: 60%;
	height: calc(100% - 5px);
	position: absolute;
	top: 0;
	left: 0;
	background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

	.activity_year_font {
		font-size: 16px;
		// font-weight: bolder;
		color: #ffffff;
		padding: 4px 16px;
		border-radius: 100px;
		background-color: #474693;
		margin-bottom: 16px;
	}

	.activity_content_font {
		font-size: 12px;
		font-weight: bold;
		color: #ffffff;
	}

	.view_activity_button {
		font-size: 14px;
		// font-weight: 500;
		color: #ffffff;
		padding: 10px 48px;
		border-radius: 4px;
		background-color: #474693;
		margin-top: 16px;
	}
}
</style>
