<template>
	<img
		v-if="!typeIsMobile"
		class="pc_imgback"
		src="../../assets/images/web/new_activity.png"
		alt=""
	/>

	<img v-else class="pc_imgback" src="../../assets/images/web/activity_pic-bg.png" />

	<div class="center_content_plate"></div>

	<div class="pc_center_plate">
		<p
			style="color: #ffffff; font-family: NotoSerifTC-Black"
			:style="{
				fontSize: !typeIsMobile ? '24px' : '20px',
				marginBottom: !typeIsMobile ? '8px' : '16px',
			}"
		>
			結緣交通指南
		</p>

		<div
			v-if="!typeIsMobile"
			class="center_flex_layout"
			style="justify-content: space-between; align-items: flex-start"
		>
			<img
				style="width: 718px; cursor: pointer"
				src="../../assets/images/web/map_image.png"
				@click="jump"
			/>

			<div class="right_content_plate">
				<p class="content_title" style="margin-top: 16px">自行開車 |</p>

				<p class="content_normal_text">
					國道三號 -
					於國道三號50公里處，下50Ａ三鶯交流道，往鶯歌方向，接台110線（復興路），左轉尖山路，接中正三路經大鶯路後，將抵達位於大鶯路1416號的無極紫勝宮。
				</p>

				<p class="content_normal_text" style="margin-top: 30px">
					北上：國道三號
					-於國道三號62公里處，下62大溪交流道，往慈湖、大溪方向，經112甲線道（國道三號大溪聯絡道），右轉台3線（內山公路）往龍潭方向，左轉桃64線（三元一街），右轉進入台4線（瑞安路一段），向左急轉後，往鶯歌方向，直行桃60鄉道（大鶯路），將抵達位於大鶯路1416號的無極紫勝宮。
				</p>

				<p class="content_title" style="margin-top: 28px">大眾運輸 |</p>

				<p class="content_normal_text">
					火車：鶯歌火車站：於鶯歌火車站搭乘計程車【約15分】至無極紫勝宮。
				</p>

				<p class="content_normal_text" style="margin-top: 30px; margin-bottom: 86px">
					高鐵：高鐵桃園站：於高鐵桃園站搭乘計程車【約30分】至無極紫勝宮。
				</p>
			</div>
		</div>

		<div v-else>
			<img
				style="width: 100%"
				src="../../assets/images/mobile/mobile_map_image.png"
				@click="jump"
			/>

			<div class="right_content_plate">
				<p class="content_title">自行開車 |</p>

				<p class="content_normal_text">
					國道三號 -
					於國道三號50公里處，下50Ａ三鶯交流道，往鶯歌方向，接台110線（復興路），左轉尖山路，接中正三路經大鶯路後，將抵達位於大鶯路1416號的無極紫勝宮。
				</p>

				<p class="content_normal_text" style="margin-top: 30px">
					北上：國道三號
					-於國道三號62公里處，下62大溪交流道，往慈湖、大溪方向，經112甲線道（國道三號大溪聯絡道），右轉台3線（內山公路）往龍潭方向，左轉桃64線（三元一街），右轉進入台4線（瑞安路一段），向左急轉後，往鶯歌方向，直行桃60鄉道（大鶯路），將抵達位於大鶯路1416號的無極紫勝宮。
				</p>

				<p class="content_title" style="margin-top: 28px">大眾運輸 |</p>

				<p class="content_normal_text">
					火車：鶯歌火車站：於鶯歌火車站搭乘計程車【約15分】至無極紫勝宮。
				</p>

				<p class="content_normal_text" style="margin-top: 30px">
					高鐵：高鐵桃園站：於高鐵桃園站搭乘計程車【約30分】至無極紫勝宮。
				</p>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
const { proxy } = getCurrentInstance();

const typeIsMobile = ref(false);

onMounted(() => {
	typeIsMobile.value = proxy.$mobile() ? true : false;

	let clientHeight = document.body.clientHeight;
	let centerHeight = document.getElementsByClassName("pc_center_plate")[0];

	if (typeIsMobile.value) {
		centerHeight.style.height = Math.floor(clientHeight - 236) + "px";
	} else {
		centerHeight.style.height = Math.floor(clientHeight - 242) + "px";
	}
});
const jump = () => {
	window.open(
		"https://www.google.com/maps/place/%E7%84%A1%E6%A5%B5%E7%B4%AB%E5%8B%9D%E5%AE%AE/@24.9286701,121.3134285,17z/data=!3m1!4b1!4m6!3m5!1s0x346819deb0990b0d:0x76951121bdd8d480!8m2!3d24.9286701!4d121.3160034!16s%2Fg%2F11h10vlwjl?entry=ttu"
	);
};
</script>

<style lang="less" scoped>
.pc_imgback {
	width: 100vw;
	position: fixed;
	bottom: 0;

	@media screen and (max-width: 500px) {
		bottom: 70px;
	}
}

.center_content_plate {
	width: 100%;
	height: 100%;
	background: linear-gradient(to bottom, #b47e31 8%, #ffbe4e 28%, #ffefc7 70%);
	position: fixed;
	top: 0;
	opacity: 0.7;
}

.pc_center_plate {
	margin: 0 59px;
	margin-top: 20px;
	position: relative;
	z-index: 2;
	overflow: auto;

	@media screen and (max-width: 500px) {
		margin: 0 24px;
	}
}

.right_content_plate {
	flex: 1;
	padding: 0 24px;
	border-radius: 8px;
	box-sizing: border-box;
	backdrop-filter: blur(10px);
	background: rgba(183, 128, 50, 0.3);
	margin-left: 24px;
	margin-bottom: 20px;

	@media screen and (max-width: 500px) {
		padding: 16px 24px;
		margin-left: 0;
		margin-top: 10px;
		margin-bottom: 40px;
	}

	.content_title {
		font-size: 20px;
		font-weight: bold;
		color: #1d1d1f;
		font-family: "Avenir";

		@media screen and (max-width: 500px) {
			font-size: 16px;
		}
	}

	.content_normal_text {
		font-size: 16px;
		color: #1d1d1f;
		font-family: "NotoSerifTC-Regular";

		@media screen and (max-width: 500px) {
			font-size: 14px;
		}
	}
}
</style>
