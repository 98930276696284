<template>
	<div style="background-color: #fffbef">
		<div style="position: relative; min-height: 680px">
			<img class="mobile_top_backimg" src="../../assets/images/mobile/mobile_top_backimg.png" />

			<div class="tab_item_plate">
				<van-tabs
					v-model:active="active"
					background="none"
					color="#ffffff"
					line-width="50vw"
					line-height="2px"
					@click-tab="leftAction"
				>
					<van-tab v-for="(tab, index) in leftBigTitle" :key="index" :title="tab.name">
						<div class="activity_title_plate">
							<div
								v-for="(newTitle, index) in newTitleArr"
								:key="{ index }"
								class="center_flex_layout"
								style="justify-content: flex-start"
								@click="detail(newTitle.id)"
							>
								<div
									class="activity_font_back center_flex_layout one_text_hidden"
									:style="{ marginTop: index === 0 ? 0 : '6px' }"
								>
									<div class="new_label center_flex_layout">
										<p class="new_label_font" style="font-family: 'Avenir'">NEW</p>
									</div>
									<p class="activity_title one_text_hidden">
										{{ newTitle.month }}&nbsp;{{ newTitle.date }}
										<span style="letter-spacing: 1px">{{ newTitle.applyName }}</span>
									</p>
								</div>
							</div>

							<div
								v-for="(dateTitle, index) in dateTitleArr"
								:key="{ index }"
								class="center_flex_layout"
								style="justify-content: flex-start; margin-top: 6px"
								@click="detail(dateTitle.id)"
							>
								<p
									class="activity_font_back activity_title"
									style="color: #eaa334; margin-top: 0; margin-left: 0px; white-space: nowrap"
								>
									{{ dateTitle.month }}&nbsp;{{ dateTitle.date }}
								</p>
								<p
									class="activity_title one_text_hidden"
									style="color: #ffffff; letter-spacing: 1px"
								>
									{{ dateTitle.applyName }}
								</p>
							</div>
						</div>
					</van-tab>
					<!--<van-tab title="即將舉辦">-->
					<!--<div class="activity_title_plate">-->
					<!--<div-->
					<!--v-for="(newTitle, index) in newTitleArr1"-->
					<!--:key="{ index }"-->
					<!--class="center_flex_layout"-->
					<!--style="justify-content: flex-start"-->
					<!--@click="detail(newTitle.id)"-->
					<!--&gt;-->
					<!--<div-->
					<!--class="activity_font_back center_flex_layout one_text_hidden"-->
					<!--:style="{ marginTop: index === 0 ? 0 : '6px' }"-->
					<!--&gt;-->
					<!--<div class="new_label center_flex_layout">-->
					<!--<p class="new_label_font" style="font-family: 'Avenir'">NEW</p>-->
					<!--</div>-->
					<!--<p class="activity_title one_text_hidden">-->
					<!--{{ newTitle.data }}<span style="letter-spacing: 1px">{{ newTitle.title }}</span>-->
					<!--</p>-->
					<!--</div>-->
					<!--</div>-->

					<!--<div-->
					<!--v-for="(dateTitle, index) in dateTitleArr1"-->
					<!--:key="{ index }"-->
					<!--class="center_flex_layout"-->
					<!--style="justify-content: flex-start; margin-top: 6px"-->
					<!--@click="detail(dateTitle.id)"-->
					<!--&gt;-->
					<!--<p-->
					<!--class="activity_font_back activity_title"-->
					<!--style="color: #eaa334; margin-top: 0; margin-left: 0; white-space: nowrap"-->
					<!--&gt;-->
					<!--{{ dateTitle.date }}-->
					<!--</p>-->
					<!--<p-->
					<!--class="activity_title one_text_hidden"-->
					<!--style="color: #ffffff; letter-spacing: 1px"-->
					<!--&gt;-->
					<!--{{ dateTitle.title }}-->
					<!--</p>-->
					<!--</div>-->
					<!--</div>-->
					<!--</van-tab>-->
				</van-tabs>

				<div class="center_flex_layout" style="margin-top: 400px">
					<img
						v-for="(iconItem, index) in platformIcons"
						:key="{ index }"
						style="width: 32px; height: 32px"
						:style="{ marginLeft: index === 0 ? 0 : '24px' }"
						:src="iconItem.url"
						@click="trafficPage(iconItem.type)"
					/>
				</div>
			</div>
		</div>

		<div style="margin: 0 15px; position: relative">
			<div class="center_flex_layout" style="margin-top: 32px">
				<div style="position: relative">
					<p class="part_title">建廟沿革</p>

					<img class="title_image" src="../../assets/images/web/xiangyun_image.png" />
				</div>
			</div>
			<div class="center_flex_layout">
				<p style="font-size: 18px; color: #474693; font-family: NotoSerifTC-Medium">
					我們的歷史與故事
				</p>
			</div>

			<img
				style="width: calc(100vw - 30px); margin-top: 12px"
				src="../../assets/images/web/jianmiao_image.png"
			/>

			<div style="margin-top: 15px">
				<p style="font-size: 24px; color: #2b2b2b">
					壹 | <span style="font-size: 16px">開基佛祖 - 與佛結緣</span>
				</p>

				<p
					style="
						font-size: 14px;
						color: #707070;
						margin-top: 8px;
						line-height: 26px;
						font-family: NotoSerifTC-Regular;
					"
				>
					壬戌年，某日，炙熱的陽光，照映進壓著海棠花的懷舊窗櫺上。兩鬢霜白、白髮銀須、臉龐睿智的佛具店老闆，齊老，正在日常的整理著店舖內的佛具，店內泛黃老舊的牆壁上，掛著清末時期御賜的匾額，光滑的磨石子地板上鋪著淺淺一層檀香粉，透亮著來回踱步的腳印。
					忽然有一群善男信女走進了店裡，齊老開口詢問：「各位師兄師姐好！有特別想找什麼嗎？」......
				</p>

				<p
					class="click_view_total hover_style"
					@click="clickViewAll('all')"
					style="font-family: NotoSerifTC-Medium"
				>
					點我看全文
				</p>
			</div>

			<img
				class="left_little_backimg"
				style="transform: translate(-50%)"
				src="../../assets/images/web/pc-golden-mountain.png"
			/>
		</div>

		<div class="center_flex_layout" style="margin-top: 127px">
			<div style="position: relative">
				<p class="part_title">師父來惹</p>

				<img class="title_image" src="../../assets/images/web/xiangyun_image.png" />
			</div>
		</div>
		<div class="center_flex_layout">
			<p style="font-size: 18px; color: #474693; font-family: NotoSerifTC-Medium">文冊系列</p>
		</div>

		<el-carousel
			ref="carouselImageOne"
			indicator-position="none"
			height="231px"
			:loop="imagesArrOne.length > 2 ? true : false"
			:autoplay="false"
			arrow="always"
			style="margin-top: 11px"
		>
			<div class="swiper_icon_plate" @click="changeImageOne('prev')" style="left: 16px">
				<img style="width: 100%; height: 100%" src="../../assets/images/web/swiper_icon.png" />
			</div>

			<div
				class="swiper_icon_plate"
				@click="changeImageOne('next')"
				style="right: 16px; transform: rotate(180deg)"
			>
				<img style="width: 100%; height: 100%" src="../../assets/images/web/swiper_icon.png" />
			</div>

			<el-carousel-item
				v-for="(itemImages, index) in imagesArrOne"
				:key="{ index }"
				style="display: flex"
				class="center_flex_layout"
			>
				<img
					style="width: 100vw"
					@click="clickViewAll('come', itemImages)"
					:src="require('../../assets/images/web/wence/' + itemImages.url)"
				/>
			</el-carousel-item>
		</el-carousel>

		<div style="position: relative">
			<div class="center_flex_layout" style="margin-top: 116px">
				<div style="position: relative">
					<p class="part_title">師父來惹</p>

					<img class="title_image" src="../../assets/images/web/xiangyun_image.png" />
				</div>
			</div>
			<div class="center_flex_layout">
				<p style="font-size: 18px; color: #474693; font-family: NotoSerifTC-Medium">師父寓也</p>
			</div>

			<el-carousel
				ref="carouselImageTwo"
				indicator-position="none"
				height="231px"
				:loop="imagesArrTwo.length > 2 ? true : false"
				:autoplay="false"
				arrow="always"
				style="position: relative; z-index: 2; margin-top: 11px"
			>
				<div class="swiper_icon_plate" @click="changeImageTwo('prev')" style="left: 16px">
					<img style="width: 100%; height: 100%" src="../../assets/images/web/swiper_icon.png" />
				</div>

				<div
					class="swiper_icon_plate"
					@click="changeImageTwo('next')"
					style="right: 16px; transform: rotate(180deg)"
				>
					<img style="width: 100%; height: 100%" src="../../assets/images/web/swiper_icon.png" />
				</div>

				<el-carousel-item
					v-for="(itemImages, index) in imagesArrTwo"
					:key="{ index }"
					style="display: flex"
					class="center_flex_layout"
				>
					<img
						style="width: 100vw"
						@click="clickViewAll('yuye', itemImages)"
						:src="require('../../assets/images/web/yuye/' + itemImages.url)"
					/>
				</el-carousel-item>
			</el-carousel>

			<img
				class="left_little_backimg"
				style="transform: rotateY(180deg); right: -64%"
				src="../../assets/images/web/pc-golden-mountain.png"
			/>
		</div>

		<div class="center_flex_layout" style="margin-top: 116px">
			<div style="position: relative">
				<p class="part_title">活動花絮</p>

				<img class="title_image" src="../../assets/images/web/xiangyun_image.png" />
			</div>
		</div>
		<div class="center_flex_layout">
			<p style="font-size: 18px; color: #474693; font-family: NotoSerifTC-Medium">回顧相冊</p>
		</div>

		<el-carousel
			ref="carouselImageThree"
			indicator-position="none"
			height="231px"
			:loop="imagesArrThree.length > 2 ? true : false"
			:autoplay="false"
			arrow="always"
			style="margin-top: 11px"
		>
			<div class="swiper_icon_plate" @click="changeImageThree('prev')" style="left: 16px">
				<img style="width: 100%; height: 100%" src="../../assets/images/web/swiper_icon.png" />
			</div>

			<div
				class="swiper_icon_plate"
				@click="changeImageThree('next')"
				style="right: 16px; transform: rotate(180deg)"
			>
				<img style="width: 100%; height: 100%" src="../../assets/images/web/swiper_icon.png" />
			</div>

			<el-carousel-item
				v-for="(itemImages, index) in imagesArrThree"
				:key="{ index }"
				style="display: flex"
				class="center_flex_layout"
			>
				<div style="position: relative">
					<img style="width: 100vw" :src="itemImages.url" />

					<div
						class="banner_pop_plate center_flex_layout"
						@click="viewActivityImages(itemImages.params)"
					>
						<div>
							<div class="center_flex_layout">
								<p class="activity_year_font">{{ itemImages.year }}</p>
							</div>

							<p class="activity_content_font">{{ itemImages.title }}</p>
						</div>
					</div>
				</div>
			</el-carousel-item>
		</el-carousel>

		<div class="center_flex_layout" style="margin-top: 116px">
			<div style="position: relative">
				<p class="part_title">服務項目</p>
				<img class="title_image" src="../../assets/images/web/xiangyun_image.png" />
			</div>
		</div>
		<div class="center_flex_layout">
			<p style="font-size: 18px; color: #474693; font-family: NotoSerifTC-Medium">
				祈求平安改善運勢
			</p>
		</div>

		<div class="center_flex_layout" style="flex-flow: row wrap; padding-bottom: 63px">
			<div
				v-for="(itemImages, index) in serviceImages"
				:key="{ index }"
				@click="serviceItemPage"
				class="service_item_image"
			>
				<img style="width: 100%; height: 100%" :src="itemImages.url" />
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
const { proxy } = getCurrentInstance();
const router = useRouter();
import { get, post } from "../../utils/request";

const leftBigTitle = ref([
	{ name: "近期活動", type: 1 },
	{ name: "即將舉辦", type: 2 },
]);
const active = ref(0);
const newTitleArr = ref([]);
const newTitleArr1 = ref([
	{ data: "新地簡介", title: "《暗夜中的善行，悄然點亮心靈》", id: "home" },
	{ data: "十一月 2023-11-01~02", title: "《觀世音菩薩出家紀念日》", id: "39" },
]);
const dateTitleArr = ref([]);
const dateTitleArr1 = ref([
	{ date: "十一月 2023-11-02", title: "《觀世音菩薩出家紀念日》", id: "39" },
	{ date: "十一月 2023-11-27", title: "《下元水官大帝聖誕》", id: "40" },
	{ date: "十一月 2023-11-30", title: "《地母至尊娘娘聖誕》", id: "41" },
]);
const leftTitleIndex = ref(1);
const imagesArrOne = ref([]);
const imagesArrTwo = ref([]);
const imagesArrThree = ref([
	{
		url: require("../../assets/images/web/activity_banner.png"),
		year: "2023",
		title: "清願普渡法會",
		content:
			"清願普渡法會是一個充滿慈悲和善意的重要活動。在這個儀式中，我們以虔誠的心情追憶先人，並祈禱他們在來世得到安寧與智慧。普渡法會的核心價值是慈悲。我們以慈悲的心情面對生命的無常，對逝去的親人和眾生抱持著無私的關懷和愛心。透過法會的舉行，我們希望能為亡者獲得解脫，也帶給自己更深層次的心靈寄託。",
		contentLine:
			"讓我們以慈悲和善意為引領，參與普渡法會，心靈得到淨化與滋養。這是一個機會，讓我們回歸內心的平靜，並向眾生散播慈悲和善意的種子。願這份慈悲和善意持續傳承下去，為所有眾生的福祉和和平作出貢獻。",
		params: "/outakesDetail?id=1&type=0",
	},
	// {
	// 	url: require("../../assets/images/web/activity_banner_04.png"),
	// 	year: "2023",
	// 	title: "清明法會",
	// 	content: "",
	// 	contentLine: "",
	// 	params: "/outakesDetail?id=1&type=0",
	// },
	{
		url: require("../../assets/images/web/activity_banner_03.png"),
		year: "2023",
		title: "大年初四",
		content: "",
		contentLine: "",
		params: "/outakesDetail?id=2&type=0",
	},
	{
		url: require("../../assets/images/web/activity_banner_02.png"),
		year: "2022",
		title: "師父來惹 座談會",
		content: "",
		contentLine: "",
		params: "/outakesDetail?id=1&type=1",
	},
	{
		url: require("../../assets/images/web/activity_banner_01.png"),
		year: "2023",
		title: "大南巡",
		content: "",
		contentLine: "",
		params: "/outakesDetail?id=2&type=1",
	},
]);
const serviceImages = ref([
	{ url: require("../../assets/images/web/diandeng_item_image.png") },
	{ url: require("../../assets/images/web/jigai_item_image.png") },
	{ url: require("../../assets/images/web/jishiwenshi_item_image.png") },
	{ url: require("../../assets/images/web/jingche_item_image.png") },
	{ url: require("../../assets/images/web/buyun_item_image.png") },
	{ url: require("../../assets/images/web/yinguo_item_image.png") },
]);
const platformIcons = ref([
	{ url: require("../../assets/images/mobile/mobilehome_youtube_icon.png"), type: "youtube" },
	{ url: require("../../assets/images/mobile/mobilehome_facebook_icon.png"), type: "facebook" },
	{ url: require("../../assets/images/mobile/mobilehome_ins_icon.png"), type: "ins" },
	{ url: require("../../assets/images/mobile/mobilehome_address_icon.png"), type: "traffic" },
]);
const scrollNumVal = ref(0);
const detail = (id) => {
	console.log(id);
	// debugger;
	router.push(`/NewEvents?id=${id}`);
};
onMounted(() => {
	getShifuCome();
	getShifuYuye();
	getActivityList();
});

const getActivityList = () => {
	get("/web/apply/home/page?type=" + leftTitleIndex.value)
		.then((res) => {
			// console.log(JSON.stringify(res));
			if (res.code === 1) {
				let resultList = res.result;
				dateTitleArr.value = resultList.filter((item) => {
					return item.newLogo === 0;
				});
				newTitleArr.value = resultList.filter((item) => {
					return item.newLogo === 1;
				});
			}
		})
		.catch((error) => {
			console.log(error);
		});
};

const leftAction = (index) => {
	leftTitleIndex.value = Number(index.name + 1);
	getActivityList();
};

const resetUrl = axios.create({
	baseURL: "",
});

const getShifuCome = () => {
	resetUrl({
		method: "GET",
		url: "/shifulaile_wence.json",
	}).then((res) => {
		// console.log(JSON.stringify(res.data.data));
		let imagesArray = new Array();
		imagesArray = res.data.data;
		let currentList = [];
		imagesArray.forEach((item, index) => {
			currentList.push({
				url: item.url,
				jumpurl: item.jumpurl,
			});
		});
		imagesArrOne.value = currentList;
	});
};

const getShifuYuye = () => {
	resetUrl({
		method: "GET",
		url: "/shifulaile_yuye.json",
	}).then((res) => {
		// console.log(JSON.stringify(res.data.data));
		let imagesArray = new Array();
		imagesArray = res.data.data;
		let currentList = [];
		imagesArray.forEach((item, index) => {
			currentList.push({
				url: item.url,
				jumpurl: item.jumpurl,
			});
		});
		imagesArrTwo.value = currentList;
	});
};
const changeImageOne = (type) => {
	if (type === "next") {
		proxy.$refs.carouselImageOne.next();
	} else {
		proxy.$refs.carouselImageOne.prev();
	}
};

const changeImageTwo = (type) => {
	if (type === "next") {
		proxy.$refs.carouselImageTwo.next();
	} else {
		proxy.$refs.carouselImageTwo.prev();
	}
};

const changeImageThree = (type) => {
	if (type === "next") {
		proxy.$refs.carouselImageThree.next();
	} else {
		proxy.$refs.carouselImageThree.prev();
	}
};

const trafficPage = (btnType) => {
	if (btnType === "youtube") {
		window.open("https://www.youtube.com/@user-lw3df6ez4t/streams");
	} else if (btnType === "facebook") {
		window.open("https://www.facebook.com/translator0202");
	} else if (btnType === "ins") {
		window.open("https://www.instagram.com/official.shifu/");
	} else {
		router.push("/mapAddress");
	}
};

const clickViewAll = (viewType, item) => {
	if (viewType === "all") {
		router.push("/jianMiao");
	} else if (viewType === "come") {
		window.open(item.jumpurl);
		// router.push("/shifuCome");
	} else if (viewType === "yuye") {
		window.open(item.jumpurl);
		// router.push("/shifuCome?name=yuye");
	}
	scrollNum();
};

const viewActivityImages = (value) => {
	router.push(value);
	scrollNum();
};

const serviceItemPage = () => {
	router.push("/serviceProject");
	scrollNum();
};

const scrollNum = () => {
	let getScrollVal = document.getElementsByClassName("global_back")[0].scrollTop;
	scrollNumVal.value = getScrollVal;
};

watch(
	() => router.currentRoute.value,
	(newValue) => {
		// console.log(newValue.name);
		setTimeout(() => {
			let scrollObject = document.getElementsByClassName("global_back")[0];
			scrollObject.scrollTo({ top: scrollNumVal.value });
		}, 50);
	},
	{ immediate: true }
);
</script>

<style lang="less" scoped>
.tab_item_plate {
	width: 100%;
	position: absolute;
	top: 0;

	.activity_title_plate {
		padding: 16px 27px;
		padding-bottom: 0;
	}

	.new_label {
		background-color: #6e94c3;
		border-radius: 4px;
	}

	.new_label_font {
		font-size: 12px;
		color: #ffffff;
		font-weight: bold;
		transform: scale(0.8);
	}

	.activity_title {
		font-size: 16px;
		color: #6e94c3;
		// font-weight: bold;
		margin-left: 4px;
	}

	.activity_font_back {
		padding: 5px 10px;
		background-color: #ffffff;
		border-radius: 8px;
		margin-top: 6px;
	}

	--van-tab-text-color: #ffffff;
	--van-tab-active-text-color: #ffffff;
	--van-tab-font-size: 20px;
	--van-tab-line-height: 30px;
}

.mobile_top_backimg {
	width: 100%;
	margin-top: -120px;
}

.part_title {
	font-size: 30px;
	color: #474693;
	// font-weight: bolder;
	font-family: "NotoSerifTC-Black";
	position: relative;
	z-index: 2;
}

.title_image {
	width: 112px;
	height: 66px;
	position: absolute;
	top: -15px;
	left: -66px;
}

.click_view_total {
	font-size: 16px;
	color: #474693;
	margin-top: 24px;
	text-decoration: underline;
	position: relative;
	z-index: 2;
	// font-family: "Avenir";
}

.left_little_backimg {
	width: 530px;
	height: 308px;
	position: absolute;
	bottom: -110px;
}

.swiper_icon_plate {
	width: 40px;
	height: 40px;
	position: absolute;
	top: calc((100% - 40px) / 2);
	cursor: pointer;
	z-index: 99;
}

.service_item_image {
	width: 176px;
	margin: 0 8px;
	margin-top: 16px;
}

.banner_pop_plate {
	width: 100%;
	height: calc(100% - 5px);
	position: absolute;
	top: 0;
	left: 0;
	background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 95%);

	.activity_year_font {
		font-size: 16px;
		// font-weight: bolder;
		color: #ffffff;
		padding: 4px 16px;
		border-radius: 100px;
		background-color: #474693;
	}

	.activity_content_font {
		font-size: 32px;
		// font-weight: bolder;
		color: #ffffff;
		margin-top: 8px;
	}
}
</style>
