<template>
	<div class="newEvents" v-if="!isMobile">
		<img src="../assets/images/web/new_activity.png" alt="" />
		<div class="center_content_plate"></div>
		<div class="contentBox">
			<van-tabs
				v-model:active="active"
				background="none"
				color="#ffffff"
				line-width="15vw"
				line-height="2px"
			>
				<van-tab v-for="(tab, index) in yearTabArray" :key="index" :title="tab.name">
					<EventContent :id="tab.id" v-if="active === index" />
				</van-tab>
			</van-tabs>
		</div>
	</div>
	<div class="newEvents_mobile" v-else>
		<img class="pc_imgback" src="../assets/images/web/activity_pic-bg.png" />
		<el-tabs v-model="active" class="demo-tabs" @tab-click="handleClick">
			<el-tab-pane
				v-for="(tab, index) in yearTabArray"
				:key="index"
				:label="&nbsp;&nbsp;tab.name&nbsp;&nbsp;"
				:name="index"
			>
				<EventContent :id="tab.id" v-if="active == index" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script setup>
import { onMounted, ref } from "vue";
const active = ref(0);
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
import { get, post } from "../utils/request";

const yearTabArray = ref([
	{ id: 3, name: "2024 甲辰年" },
	{ id: 2, name: "2023 癸卯年" },
]);

onMounted(() => {
	getYearTab();
});

const getYearTab = () => {
	get("/web/apply/year/header")
		.then((res) => {
			console.log(res);
			if (res.code === 1) {
				let returnArray = getUniqueValues(yearTabArray.value, res.result);
				yearTabArray.value = yearTabArray.value.concat(returnArray);
			}
		})
		.catch((error) => {
			console.log(error);
		});
};

const getUniqueValues = (arr1, arr2) => {
	let addNewYear = [];
	arr2.map((item, index) => {
		if (item.id == 1) {
			addNewYear.unshift({
				id: item.id,
				name: item.name,
			});
		} else if (item.id >= 4) {
			addNewYear.push({
				id: item.id,
				name: item.name,
			});
		}
	});
	return addNewYear;
};
</script>

<style lang="less" scoped>
.newEvents {
	> img {
		width: 100vw;
		position: absolute;
		bottom: 0px;
	}
	.contentBox {
		padding: 15px 59px 0px 59px;
	}
	--van-tab-text-color: #ffffff;
	--van-tab-active-text-color: #ffffff;
	--van-tab-font-size: 24px;
	--van-tab-line-height: 40px;
}
.newEvents_mobile {
	// background-image: url(../assets/images/web/activity_pic-bg.png);
	.demo-tabs {
		/deep/ .el-tabs__nav-wrap::after {
			height: 0px !important;
		}
		/deep/ .el-tabs__active-bar {
			background-color: #ffffff !important;
		}
		/deep/ .el-tabs__item {
			margin: 0 15px !important;
			padding: 8px 2px !important;
			color: #ffffff !important;
			font-size: 20px;
		}
		/deep/ .el-tabs__nav-prev,
		.el-tabs__nav-next {
			display: none !important;
		}
		/deep/ .el-tabs__nav-wrap.is-scrollable {
			padding: 0px !important;
		}
	}
}
.pc_imgback {
	width: 100vw;
	position: fixed;
	bottom: 70px;
	left: 0px;
}
</style>

<style>
.el-tabs__nav-scroll {
	overflow: auto !important;
}
</style>
