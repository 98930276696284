let flag = true;
export const limit =(fn: any, delay: any)=> {
    if (flag) {
        fn();
        flag = false;

        setTimeout(() => {
            flag = true;
        }, delay)
    }
};