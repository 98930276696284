<template>
    <div>
        <PcFanshu v-if="!typeIsMobile" />
        <MobileFanshu v-else/>
    </div>
</template>

<script setup>
    import { ref, reactive, getCurrentInstance, onMounted } from "vue";
    const { proxy } = getCurrentInstance();

    const typeIsMobile = ref(false);

    import PcFanshu from "../JianMiao/fanshu.vue";
    import MobileFanshu from '../JianMiao/mobileFanshu.vue';

    onMounted(() => {
        typeIsMobile.value = proxy.$mobile() ? true : false;
    });
</script>
