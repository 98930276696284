import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

import Home from '../views/Home.vue';
import HomeMain from '../views/homeMain/HomeMain.vue';
import NewEvents from '../views/NewEvents.vue';
import ShifuCome from '../views/shifuCome/ShifuCome.vue'
import Outtakes from '../views/Outtakes/Outtakes.vue'
import OuttakesDetail from '../views/Outtakes/OuttakesDetail.vue'
import JianMiao from '../views/JianMiao/main.vue';
import ServiceProject from '../views/serviceProject.vue';
import MapAddress from '../views/JianMiao/mapAddress.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/homeMain',
    component: Home,
    children: [
      {
        path: 'homeMain',
        name: 'homeMain',
        component: HomeMain,
        meta: {
          keepAlive: true
        }
      },
      {
        path: 'newEvents',
        name: 'newEvents',
        component: NewEvents
      },
      {
        path: 'shifuCome',
        name: 'shifuCome',
        component: ShifuCome,
      },
      {
        path: 'outtakes',
        name: 'outtakes',
        component: Outtakes,
        meta: {
          keepAlive: true
        }
      },
      {
        path: 'outakesDetail',
        name: 'outakesDetail',
        component: OuttakesDetail,
      },
      {
        path: 'jianMiao',
        name: 'jianMiao',
        component: JianMiao
      },
      {
        path: 'serviceProject',
        name: 'serviceProject',
        component: ServiceProject
      },
      {
        path: 'mapAddress',
        name: 'mapAddress',
        component: MapAddress
      },
    ]
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
