<template>
	<div class="shifuCome" v-if="!isMobile">
		<img src="../../assets/images/web/new_activity.png" alt="" />
		<div class="center_content_plate"></div>
		<div class="shifubox">
			<el-tabs v-model="active" class="demo-tabs">
				<el-tab-pane
					label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;文冊系列&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
					:name="0"
				>
					<div class="imglist">
						<img
							v-for="(item, index) in shifulist"
							:key="index"
							:src="require('../../assets/images/web/wence/' + item.url)"
							alt=""
							@click="handleClick(item)"
						/>
					</div>
				</el-tab-pane>
				<el-tab-pane
					label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;師父寓也&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
					:name="1"
				>
					<div class="imglist">
						<img
							v-for="(item, index) in comelist"
							:key="index"
							:src="require('../../assets/images/web/yuye/' + item.url)"
							alt=""
							@click="handleClick(item)"
						/>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
	<div class="shifuCome_mobile" v-else>
		<img class="pc_imgback" src="../../assets/images/web/activity_pic-bg.png" />
		<div class="contentBox">
			<van-tabs
				v-model:active="active"
				background="none"
				color="#ffffff"
				line-width="50vw"
				line-height="2px"
			>
				<van-tab title="文冊系列">
					<div class="imglist" :style="{ height: clientHeight }">
						<img
							v-for="(item, index) in shifulist"
							:key="index"
							:src="require('../../assets/images/web/wence/' + item.url)"
							alt=""
							@click="handleClick(item)"
						/>
					</div>
				</van-tab>
				<van-tab title="師父寓也" class="title">
					<div class="imglist" :style="{ height: clientHeight }">
						<img
							v-for="(item, index) in comelist"
							:key="index"
							:src="require('../../assets/images/web/yuye/' + item.url)"
							alt=""
							@click="handleClick(item)"
						/>
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
const router = useRouter();
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const active = ref(0);
const imglistoneUrl = ref("");
const shifulist = ref([]);
const comelist = ref([]);
const clientHeight = ref(0);
clientHeight.value = document.body.clientHeight - 281 + "px";
onMounted(() => {
	let route = router.currentRoute.value.query;
	if (route.name == "yuye") {
		active.value = 1;
	} else {
		active.value = 0;
	}
	getlist();
});
const resetUrl = axios.create({
	baseURL: "",
});
const getlist = () => {
	resetUrl({
		method: "GET",
		url: "/shifulaile_wence.json",
	}).then((res) => {
		shifulist.value = res.data.data;
	});
	resetUrl({
		method: "GET",
		url: "/shifulaile_yuye.json",
	}).then((res) => {
		comelist.value = res.data.data;
	});
};
const handleClick = (item) => {
	window.open(item.jumpurl);
};
// watch(
// 	() => active.value,
// 	(newValue) => {
// 		console.log(newValue);
// 		if (newValue == 0) {
// 			window.location.hash = "#/shifuCome";
// 		} else {
// 			if (window.location.hash.includes("name=yuye")) return;
// 			window.location.hash = "#/shifuCome?name=yuye";
// 		}
// 	},
// 	{ immediate: true }
// );
</script>

<style lang="less" scoped>
&::-webkit-scrollbar {
	display: none;
}
.shifuCome {
	.shifubox {
		padding: 28px 59px 0px 59px;
	}
	box-sizing: border-box;
	overflow: hidden;
	> img {
		width: 100vw;
		position: absolute;
		bottom: 0px;
	}
	.imglist {
		height: calc(100vh - 304px);
		overflow: auto;
		img {
			width: calc((100% - 72px) / 4);
			margin-left: 24px;
			margin-top: 24px;
			border-radius: 8px;
			cursor: pointer;
		}
		img:nth-child(4n + 1) {
			margin-left: 0px;
		}
		img:nth-child(-n + 4) {
			margin-top: 0px;
		}
	}
}
.demo-tabs {
	/deep/ .el-tabs__nav-wrap::after {
		height: 0px !important;
	}
	/deep/ .el-tabs__active-bar {
		background-color: #ffffff !important;
	}
	/deep/ .el-tabs__item {
		padding: 8px 2px !important;
		color: #ffffff !important;
		font-size: 24px;
	}
	/deep/ .el-tabs__nav-prev,
	.el-tabs__nav-next {
		display: none !important;
	}
	/deep/ .el-tabs__nav-wrap.is-scrollable {
		padding: 0px !important;
	}
}
.shifuCome_mobile {
	--van-tab-text-color: #ffffff;
	--van-tab-active-text-color: #ffffff;
	--van-tab-font-size: 20px;
	--van-tab-line-height: 40px;
	.contentBox {
		.imglist {
			height: calc(100vh - 281px);
			overflow: auto;
			padding: 14px 24px 20px 24px;
			box-sizing: border-box;
			img {
				width: 100%;
				margin-top: 24px;
				border-radius: 8px;
			}
			img:first-child {
				margin-top: 0px;
			}
		}
	}
}
.pc_imgback {
	width: 100vw;
	position: fixed;
	bottom: 70px;
	left: 0px;
}
/deep/ .van-tab {
	font-family: "NotoSerifTC-Black" !important;
}
// .van-tab__text--ellipsis {
// 	font-family: "NotoSerifTC-Black" !important;
// }
</style>
