<template>
	<div class="footer_plate">
		<div v-if="!typeIsMobile" style="margin: 0 59px">
			<p class="footer_font" style="font-family: NotoSerifTC-Black">無極紫勝宮</p>

			<p class="footer_fonts">劃撥帳號：need number here</p>

			<p class="footer_fonts">
				廟址：33542 台灣桃園市大溪區大鶯路1416號 | 電話：03 380 8702 |
				e-mail：mailboxaddress@gmail.com
			</p>

			<p class="footer_fonts">本站內所有圖片及文字皆有著作權保護，請勿任意翻載複製使用，謝謝！</p>
		</div>

		<div v-else>
			<p class="footer_font" style="font-family: NotoSerifTC-Black">無極紫勝宮</p>

			<p class="footer_fonts">廟址：33542 台灣桃園市大溪區大鶯路1416號</p>

			<p class="footer_fonts">電話：03 380 8702</p>

			<p class="footer_fonts">本站內所有圖片及文字皆有著作權保護</p>

			<p class="footer_fonts">請勿任意翻載複製使用，謝謝！</p>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
const { proxy } = getCurrentInstance();
const router = useRouter();

const typeIsMobile = ref(false);
const needFixed = ref(true);

onMounted(() => {
	typeIsMobile.value = proxy.$mobile() ? true : false;
});

watch(
	() => router.currentRoute.value,
	(newValue) => {
		// console.log(newValue.name);
		if (newValue.name === "homeMain") {
			needFixed.value = false;
		} else {
			needFixed.value = true;
		}
	},
	{ immediate: true }
);
</script>

<style lang="less" scoped>
.footer_plate {
	width: 100%;
	padding: 20px 0;
	background-color: #474693;
	position: relative;
	z-index: 99;

	@media screen and (max-width: 500px) {
		padding: 8px 0;
		// margin-bottom: 80px;
	}

	.footer_font {
		font-size: 14px;
		color: #fafafa;
		text-align: center;
		line-height: 20px;
	}
}
.footer_fonts {
	font-size: 14px;
	color: #fafafa;
	line-height: 20px;
	text-align: center;
	font-family: "NotoSerifTC-Regular";
	letter-spacing: 0.5px;
}

.fixed_footer {
	position: fixed !important;
	bottom: 0;
}
</style>
