import axios from 'axios'
import { baseURL } from './global'
import router from '../router/index'
import { ElMessage, ElLoading } from 'element-plus'
// 创建 axios 实例
axios.defaults.baseURL = baseURL;
axios.interceptors.request.use(
    config => {
        config.data = JSON.stringify(config.data);
        // if (localStorage.access_token) {
        //     config.headers.common['Authorization'] = 'Bearer ' + localStorage.access_token
        // }
        // config.headers.common['Authorization'] = localStorage.access_token;
        //转loading

        return config
    },
    error => {
        return Promise.reject(error);
    }
)
//响应拦截器
axios.interceptors.response.use(
    response => {
        let status = response.status;
        if (status === 200) {
            if (response.data.code === 0) {
                ElMessage.error(response.data.message)
            }
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是200的情况
    error => {
        if (error.response.status) {
            let status = error.response.status;
            switch (status) {
                case 401:
                    localStorage.setItem('access_token', '')
                    // if(error.response.config.url.includes('/auth/form')){
                    //     ElMessage.error(error.response.data.message)
                    // }else{
                    //     ElMessage.error(error.response.data.message)
                    //     setTimeout(()=>{
                    //         router.push({
                    //             path:'/login'
                    //         })
                    //     },1000)
                    // }
                    break;
                default:
                    ElMessage.error('Oops, this is a error message.')
            }
            return Promise.reject(error.response);
        }
    }
)
export function post(url: any, params: any, headers: any, showLoading = true) {
    let loading: any
    if (showLoading) {
        loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
        })
    }
    return new Promise((resolve, reject) => {
        axios.post(url, params, {
            headers: headers || {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
            .then(res => {
                if (showLoading) {
                    loading.close()
                }
                resolve(res.data)
            })
            .catch(err => {
                if (showLoading) {
                    loading.close()
                }
                reject(err)
            });
    })
}
export function get(url: any, params: any, showLoading = true) {
    let loading: any
    if (showLoading) {
        loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
        })
    }
    return new Promise((resolve, reject) => {
        axios.get(url, params)
            .then(res => {
                if (showLoading) {
                    loading.close()
                }
                resolve(res.data)
            }).catch(err => {
                if (showLoading) {
                    loading.close()
                }
                reject(err)
            });
    })
}

export function exportExcel(url: any, method: any, data: any, name: any) {
    // resolve(res.data)
    axios({
        method: method,
        url: url,
        responseType: 'arraybuffer',
        data: data,
    }).then(res => {
        let link = document.createElement('a');// 创建隐藏的可下载链接
        link.download = `${name}.xlsx`;
        link.style.display = 'none';
        let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' }); // 字符内容转变成blob地址
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();// 触发点击
        document.body.removeChild(link);// 然后移除
    }).catch(err => {
        console.log('err', err)
    })
}
