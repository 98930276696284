<template>
	<div v-if="!isMobile">
		<div class="titleTop" v-if="showStatus == 'list'">
			<div class="font1">活動一覽表</div>
			<div class="font3" style="font-family: NotoSerifTC-Regular">
				共 {{ list.length }} 筆活動訊息
			</div>
		</div>
		<div
			class="titleTop"
			v-else-if="showStatus == 'detail' || showStatus == 'home'"
			style="justify-content: flex-start; cursor: pointer; width: max-content"
			@click="handleBack"
		>
			<img src="../../assets/images/web/activity_goback.png" alt="" />
			<div class="font1">返回</div>
		</div>
		<div class="scrollbox">
			<div class="scrollview" v-if="showStatus == 'list'" id="mobileIn">
				<div class="listRow" v-for="item in list" :key="item.id" @click="handleClick(item)">
					<div class="listRowLeft">
						<span>{{ item.month }}&nbsp;</span>
						<span>{{ item.date }}</span>
					</div>
					<div class="listRowRight">{{ item.applyName }}</div>
				</div>
			</div>
			<div class="scrollview" v-else-if="showStatus == 'detail'" id="mobileIn">
				<div v-if="returnContent" class="detailbox">
					<div class="font1" style="font-size: 20px">
						{{ startTime }}(農曆{{ lunarDate }})-{{ endTime }}(農曆{{ lunarDates }})
					</div>
					<div class="font1" style="margin-top: 8px">{{ returnContent.applyName }}</div>
					<div class="font1" style="margin-top: 8px; font-size: 20px">
						{{ returnContent.applyContent }}
					</div>
					<div class="line"></div>
					<div class="content_style" v-html="returnContent.applyDetail"></div>
				</div>

				<div v-if="content" class="detailbox">
					<div class="font1" style="font-size: 20px">{{ content.titleone }}</div>
					<div class="font1" style="margin-top: 8px">{{ content.titleTwo }}</div>
					<div class="font1" style="margin-top: 8px; font-size: 20px">{{ content.titleThree }}</div>
					<div class="line"></div>
					<div class="font1" style="font-size: 18px; margin-top: 16px">{{ content.titleFour }}</div>
					<div
						class="font1 write"
						:style="{ fontSize: '18px', marginTop: index > 0 ? '16px' : '16px' }"
						v-for="(item, index) in content.contentlist"
						:key="index"
					>
						{{ item }}
					</div>
					<p class="click_view_total hover_style" v-if="content.url" @click="jumpTo(content.url)">
						按我報名
					</p>
					<div style="margin-top: 16px" v-if="content">
						<img
							v-for="(item, index) in content.imgurlList"
							:key="index"
							:src="item"
							alt=""
							style="max-height: 321px; margin-right: 20px; margin-bottom: 16px"
						/>
					</div>
					<div class="font1" style="font-size: 18px; margin-top: 16px">{{ content.titleFive }}</div>
					<div
						class="font1"
						:style="{ fontSize: '18px', marginTop: index > 0 ? '16px' : '16px' }"
						v-for="(item, index) in content.contentlist2"
						:key="index"
					>
						{{ item }}
					</div>
					<div style="margin-top: 16px" v-if="content.imgurlList2 && content.imgurlList2.length">
						<img
							v-for="(item, index) in content.imgurlList2"
							:key="index"
							:src="item"
							alt=""
							style="height: 321px; margin-right: 20px; margin-bottom: 16px"
						/>
					</div>
				</div>
			</div>
			<div class="scrollview" v-else-if="showStatus == 'home'" id="mobileIn">
				<div class="detailbox">
					<div class="font1" style="margin-top: 8px">《暗夜中的善行，悄然點亮心靈》</div>
					<div class="line"></div>
					<div
						class="font1"
						:style="{ fontSize: '18px', marginTop: index > 0 ? '16px' : '16px' }"
						v-for="(item, index) in contents"
						:key="index"
					>
						{{ item.name }}
					</div>
					<div style="margin-top: 16px">
						<img
							src="../../assets/images/web/shanxing.png"
							alt=""
							style="max-height: 321px; margin-right: 20px; margin-bottom: 16px"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="bottombox">
			<img src="../../assets/images/web/btn-youtube.png" alt="" @click="navigateTo(1)" />
			<img src="../../assets/images/web/btn-fb.png" alt="" @click="navigateTo(2)" />
			<img src="../../assets/images/web/btn-ig.png" alt="" @click="navigateTo(3)" />
			<img src="../../assets/images/web/btn-traffic.png" alt="" @click="navigateTo(4)" />
		</div>
	</div>
	<div v-else class="mobileOverFlow">
		<div class="titleTop_mobile" v-if="showStatus == 'list'">
			<div class="font1" style="font-size: 20px">活動一覽表</div>
			<div class="font3" style="font-size: 14px; font-family: NotoSerifTC-Regular">
				共 {{ list.length }} 筆活動訊息
			</div>
		</div>
		<div
			class="titleTop_mobile"
			v-if="showStatus == 'detail' || showStatus == 'home'"
			style="cursor: pointer"
			@click="handleBack"
		>
			<div class="flexRow">
				<img src="../../assets/images/web/activity_goback.png" alt="" />
				<div class="font1" style="font-size: 20px">返回</div>
			</div>
			<div class="font2" style="font-size: 14px; font-family: NotoSerifTC-Regular">
				發布日期 :{{ publishTime }}
			</div>
		</div>
		<div :style="{ height: clientHeight, overflow: 'auto' }" id="mobileOut">
			<div
				class="scrollbox"
				style="padding-top: 16px; padding-right: 16px; height: max-content; min-height: 300px"
			>
				<div class="scrollview" v-if="showStatus == 'list'" id="mobileIn">
					<div
						class="listRow"
						v-for="item in list"
						:key="item.id"
						@click="handleClick(item)"
						style="display: block"
					>
						<div class="listRowLeft" style="width: max-content; font-size: 14px">
							<span>{{ item.month }}&nbsp;</span>
							<span>{{ item.date }}</span>
						</div>
						<div class="listRowRight" style="margin-left: 0px; font-size: 16px">
							{{ item.applyName }}
						</div>
					</div>
				</div>
				<div class="scrollview" v-else-if="showStatus == 'detail'" id="mobileIn">
					<div v-if="returnContent" class="detailbox">
						<div class="font1" style="font-size: 16px">
							{{ startTime }}(農曆{{ lunarDate }})-{{ endTime }}(農曆{{ lunarDates }})
						</div>
						<div class="font1" style="font-size: 20px; margin-top: 6px">
							{{ returnContent.applyName }}
						</div>
						<div class="font1" style="font-size: 16px; margin-top: 6px">
							{{ returnContent.applyContent }}
						</div>
						<div class="line"></div>
						<div class="mobile_content_style" v-html="returnContent.applyDetail"></div>
					</div>

					<div v-if="content" class="detailbox">
						<div class="font1" style="font-size: 16px">{{ content.titleone }}</div>
						<div class="font1" style="font-size: 20px; margin-top: 6px">{{ content.titleTwo }}</div>
						<div class="font1" style="font-size: 16px; margin-top: 6px">
							{{ content.titleThree }}
						</div>
						<div class="line"></div>
						<div class="font1" style="font-size: 14px; margin-top: 16px">
							{{ content.titleFour }}
						</div>
						<div
							class="font1 write"
							:style="{ fontSize: '14px', marginTop: index > 0 ? '6px' : '6px' }"
							v-for="(item, index) in content.contentlist"
							:key="index"
						>
							{{ item }}
						</div>
						<p
							class="click_view_totals hover_style"
							v-if="content.url"
							@click="jumpTo(content.url)"
						>
							按我報名
						</p>
						<div style="margin-top: 16px" v-if="content">
							<img
								v-for="(item, index) in content.imgurlList"
								:key="index"
								:src="item"
								alt=""
								style="
									max-height: 321px;
									margin-right: 20px;
									margin-bottom: 16px;
									max-width: 100%;
									object-fit: contain;
								"
							/>
						</div>
						<div class="font1" style="font-size: 14px; margin-top: 16px">
							{{ content.titleFive }}
						</div>
						<div
							class="font1"
							:style="{ fontSize: '14px', marginTop: index > 0 ? '6px' : '6px' }"
							v-for="(item, index) in content.contentlist2"
							:key="index"
						>
							{{ item }}
						</div>
						<div style="margin-top: 16px" v-if="content.imgurlList2 && content.imgurlList2.length">
							<img
								v-for="(item, index) in content.imgurlList2"
								:key="index"
								:src="item"
								alt=""
								style="
									max-height: 321px;
									margin-right: 20px;
									margin-bottom: 16px;
									max-width: 100%;
									object-fit: contain;
								"
							/>
						</div>
					</div>
				</div>
				<div class="scrollview" v-else-if="showStatus == 'home'" id="mobileIn">
					<div class="detailbox">
						<div class="font1" style="margin-top: 8px">《暗夜中的善行，悄然點亮心靈》</div>
						<div class="line"></div>
						<div
							class="font1 write"
							:style="{ fontSize: '14px', marginTop: index > 0 ? '16px' : '16px' }"
							v-for="(item, index) in contents"
							:key="index"
						>
							{{ item.name }}
						</div>
						<div style="margin-top: 16px">
							<img
								src="../../assets/images/web/shanxing.png"
								alt=""
								style="height: 321px; margin-right: 20px; margin-bottom: 16px"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="mobilebottom">
				<img src="../../assets/images/web/btn-youtube.png" alt="" @click="navigateTo(1)" />
				<img src="../../assets/images/web/btn-fb.png" alt="" @click="navigateTo(2)" />
				<img src="../../assets/images/web/btn-ig.png" alt="" @click="navigateTo(3)" />
				<img src="../../assets/images/web/btn-traffic.png" alt="" @click="navigateTo(4)" />
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import { defineProps } from "vue";
import { useRouter, useRoute } from "vue-router";
import LunarCalendar from "lunar-calendar";
import { datalist } from "./data.js";
const { proxy } = getCurrentInstance();
const router = useRouter();
const route = useRoute();
import { get, post } from "../../utils/request";

const props = defineProps(["id"]);
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const showStatus = ref("list");
const content = ref("");
const returnContent = ref("");
const list = ref([]);
const scrollY = ref(0);
const scrollY2 = ref(0);
const lunarDate = ref("");
const lunarDates = ref("");
const startTime = ref("");
const endTime = ref("");
const publishTime = ref("");
const contents = ref([
	{
		name: "在這片遠離塵囂的山林中，存在著一個讓人心靈放鬆的所在。這地方並非富麗堂皇的豪宅，亦非雕梁畫棟的宮殿，而是一個眾多不分你我的人們一同努力打造出來的淨地。",
	},
	{
		name: "當晨曦的曙光輕撫山脈的輪廓，人們紛紛挽起袖子，揮汗除草，默默承受著蚊蟲叮咬的滋味，用雙手一點一滴的打造著。每一份努力都是為了將這片土地變成一個避世的樂園，一個遠離喧囂的靜心之地。",
	},
	{
		name: "當懷著愛的汗水滴落在土地上，如同天然的灌溉，悄悄滋養著一片美麗的花海，雖然臉上滿是疲憊，但心中卻充滿富足與喜悅。人們的辛勞並不僅僅是為了自己，更是為了讓這座住所成為一個共享的福地。",
	},
	{
		name: "這裡的每一片綠葉，每一顆石頭，都承載著人們的氣息。付出的不僅是勞動，更是對美好未來的堅定信念。然而，我們也明白，這樣的夢想需要更多的經費、資源以及有志之士的支持。",
	},
	{
		name: "我們並不想以「積功德」、「植福田」這樣的文字來作為交換，因為每個人都有自己的方式來關心這片新地，無論是如細雨般的捐款，或是一句溫暖的感謝。",
	},
	{
		name: "只是，如果您也支持這塊福地的落成，歡迎您隨時加入，以各種方式一起盡一份力，無論是大小額捐款、參與淨山、或提供各項物資。",
	},
	{
		name: "這塊福地的建造需要我們每個人的參與，讓我們為這片山林注入更多的生機與靈魂，享受著大自然的恩賜，品味著平靜與寧靜，成為我們心靈的避風港。",
	},
	{ name: "在這片福澤林地中，每一個人都是建造者，也是受惠者。" },
]);
const handleClick = (item) => {
	console.log(666);
	const mobileOut = document.getElementById("mobileOut");
	const mobileIn = document.getElementById("mobileIn").scrollTop;
	scrollY.value = mobileIn;
	if (mobileOut) {
		scrollY2.value = mobileOut.scrollTop;
		mobileOut.scrollTo({
			top: 0,
		});
	}
	showStatus.value = "detail";

	if (item.content) {
		content.value = item.content;
	} else {
		getYearContent(item.id);
	}
};
const handleBack = () => {
	// showStatus.value = "list";
	if (showStatus.value == "detail") {
		showStatus.value = "list";
		content.value = {};
		setTimeout(() => {
			const mobileOut = document.getElementById("mobileOut");
			const mobileIn = document.getElementById("mobileIn");
			if (mobileIn) {
				mobileIn.scrollTo({
					top: scrollY.value,
				});
			}
			if (mobileOut) {
				mobileOut.scrollTo({
					top: scrollY2.value,
				});
			}
			scrollY.value = 0;
			scrollY2.value = 0;
		});
	} else if (showStatus.value == "home") {
		router.push("/HomeMain");
		showStatus.value = "list";
	}
};
const navigateTo = (item) => {
	if (item == 1) {
		window.open("https://www.youtube.com/@user-lw3df6ez4t/streams");
	} else if (item == 2) {
		window.open("https://www.facebook.com/translator0202");
	} else if (item == 3) {
		window.open("https://www.instagram.com/official.shifu/");
	} else if (item == 4) {
		router.push("/mapAddress");
	}
};
const jumpTo = (item) => {
	window.open(item);
};
onMounted(() => {
	// showStatus.value == "";
	console.log(route.query.id);
	if (route.query.id == "home") {
		showStatus.value = "home";
		// debugger;
		return;
	}
	// debugger;
	let filterlist = datalist.filter((item) => item.year == props.id);
	list.value = filterlist;
	getYearList();
	if (route.query.id) {
		if (filterlist.length > 0) {
			let contentValue = {};
			filterlist.map((item) => {
				if (item.id == route.query.id) {
					contentValue = item.content;
				}
			});
			content.value = contentValue;
		} else {
			getYearContent(route.query.id);
		}
		showStatus.value = "detail";
		window.location.replace("#/NewEvents");
	}
});
const clientHeight = ref(0);
clientHeight.value = document.body.clientHeight - 327 + "px";

const getYearList = () => {
	get("/web/apply/list?yearId=" + props.id)
		.then((res) => {
			if (res.code === 1) {
				list.value = list.value.concat(res.result);
			}
		})
		.catch((error) => {
			console.log(error);
		});
};

const getYearContent = (id) => {
	get("/web/apply/detail?id=" + id)
		.then((res) => {
			console.log(list.value, "list");
			if (res.code === 1) {
				returnContent.value = res.result;
				const date = new Date(returnContent.value.startTime);
				const dates = new Date(returnContent.value.endTime);
				const year = date.getFullYear();
				const month = date.getMonth() + 1;
				const day = date.getDate();
				const years = dates.getFullYear();
				const months = dates.getMonth() + 1;
				const days = dates.getDate();
				const lunarDateObj = LunarCalendar.solarToLunar(year, month, day);
				const lunarDateObjs = LunarCalendar.solarToLunar(years, months, days);
				lunarDate.value = `${lunarDateObj.lunarMonth}/${lunarDateObj.lunarDay}`;
				lunarDates.value = `${lunarDateObjs.lunarMonth}/${lunarDateObjs.lunarDay}`;
				startTime.value = proxy.$date(res.result.startTime).format("YYYY-MM/DD");
				endTime.value = proxy.$date(res.result.endTime).format("YYYY-MM/DD");
				publishTime.value = proxy.$date(res.result.publishTime).format("YYYY-MM-DD HH:mm:ss");
				console.log(res);
			}
		})
		.catch((error) => {
			console.log(error);
		});
};
</script>

<style lang="less" scoped>
.click_view_total {
	font-size: 16px;
	color: white;
	background-color: #474693;
	margin-top: 24px;
	// text-decoration: underline;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 96px;
	height: 31px;
	position: relative;
	border-radius: 100px;
	z-index: 2;
}
.click_view_totals {
	font-size: 14px;
	color: white;
	background-color: #474693;
	margin-top: 24px;
	// text-decoration: underline;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 96px;
	height: 31px;
	position: relative;
	border-radius: 100px;
	z-index: 2;
}
.line {
	height: 1px;
	background-color: #ffffff;
	margin-top: 16px;
}
.font1 {
	font-size: 24px;
	color: #ffffff;
	// font-weight: "NotoSerifTC-Black";
}
.font2 {
	font-size: 16px;
	color: #ffffff;
}
.font3 {
	font-size: 16px;
	color: #ffffff;
}
.titleTop {
	display: flex;
	align-items: center;
	justify-content: space-between;
	> img {
		width: 16px;
		height: 16px;
		margin-right: 8px;
	}
}
.scrollbox {
	margin-top: 8px;
	background-color: rgba(183, 128, 50, 0.3);
	border-radius: 8px;
	height: calc(100vh - 411px);
	width: 100%;
	padding: 32px;
	box-sizing: border-box;
	// backdrop-filter: blur(10px);
	.scrollview {
		overflow: auto;
		height: 100%;
		.detailbox {
			margin-right: 40px;
		}
	}
	.listRow {
		display: flex;
		align-items: center;
		margin-top: 16px;
		.listRowLeft {
			background-color: #ffffff;
			padding: 8px;
			color: #eaa334;
			border-radius: 8px;
			font-size: 16px;
			flex-shrink: 0;
			font-family: NotoSerifTC-Black;
		}
		.listRowRight {
			color: #ffffff;
			padding: 6.5px 0px 6.5px 8px;
			border-radius: 8px;
			font-size: 18px;
			margin-left: 16px;
			margin-right: 40px;
			flex: 1;
			cursor: pointer;
			font-family: NotoSerifTC-Black;
		}
		.listRowRight:hover {
			background-color: rgba(187, 130, 52, 0.64);
		}
	}
}
.bottombox {
	margin-top: 27px;
	display: flex;
	align-items: center;
	margin-bottom: 28px;
	> img {
		height: 32px;
		margin-left: 24px;
		cursor: pointer;
	}
	> img:first-child {
		margin-left: 0px;
	}
}
.mobilebottom {
	margin-top: 18px;
	margin-bottom: 20px;
	display: block;
	> img {
		height: 30px;
		display: block;
	}
}
.scrollview::-webkit-scrollbar {
	display: block !important;
}
.scrollview::-webkit-scrollbar {
	width: 8px;
}
.scrollview::-webkit-scrollbar-thumb {
	background: #474693;
	border-radius: 800px;
}
.scrollview::-webkit-scrollbar-track {
	background: #ffffff;
	border-radius: 800px;
}
.titleTop_mobile {
	margin-top: 7px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	img {
		width: 16px;
		height: 16px;
		margin-right: 8px;
	}
}
.flexRow {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.mobileOverFlow {
	padding-left: 10px;
	padding-right: 10px;
}
.write {
	letter-spacing: 0.5px;
}
</style>

<style>
.content_style > p {
	color: #ffffff;
	font-size: 18px;
	margin-top: 16px;
}

.mobile_content_style > p {
	color: #ffffff;
	font-size: 14px;
	margin-top: 6px;
}
</style>
