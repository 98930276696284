<template>
	<router-view />
	<div class="page_tips">
		<p>横屏体验效果不佳</p>
		<p>请竖屏，并关闭手机旋转</p>
	</div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
const { proxy } = getCurrentInstance();
const router = useRouter();
const typeIsMobile = ref(false);

onMounted(() => {
	typeIsMobile.value = proxy.$mobile() ? true : false;
	if (typeIsMobile.value) {
		window.addEventListener(
			"onorientationchange" in window ? "orientationchange" : "resize",
			screenFn,
			false
		);
	}
});

// 旋转屏幕方向监听横竖屏
const screenFn = () => {
	const scrollHeight = document.documentElement.clientHeight || document.body.clientHeight;

	if (window.orientation === 90 || window.orientation === -90) {
		// 横屏

		document.getElementsByClassName("page_tips")[0].style.height = scrollHeight + "px";
		document.getElementsByClassName("page_tips")[0].style.display = "block";
	} else {
		// 竖屏

		document.getElementsByClassName("page_tips")[0].style.display = "none";
	}
};

// 路由监听横竖屏
watch(
	() => router.currentRoute.value,
	(newValue) => {
		// console.log(newValue.name);
		if (typeIsMobile.value) {
			// console.log("？？？", typeIsMobile.value);
			const _currentWidth = document.documentElement.clientWidth || document.body.clientWidth;
			const _currentHeight = document.documentElement.clientHeight || document.body.clientHeight;

			if (_currentWidth > _currentHeight) {
				// 横屏

				document.getElementsByClassName("page_tips")[0].style.height = _currentHeight + "px";
				document.getElementsByClassName("page_tips")[0].style.display = "block";
			} else {
				// 竖屏

				document.getElementsByClassName("page_tips")[0].style.display = "none";
			}
		}
	},
	{ immediate: true }
);
</script>

<style lang="less">
@import "@/assets/app.less";
#app {
	font-family: "NotoSerifTC-Black";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
// @font-face {
// 	font-family: "songti";
// 	src: url("@/assets/font/NotoSerifTC-Regular.otf") format("opentype");
// }
html,
body,
#app {
	/*color: #606266;*/
	width: 100%;
	height: 100%;
	/*min-height: 100vh;*/
	// font-family: "Microsoft YaHei", "Avenir", Helvetica, Arial, sans-serif;
	overflow: hidden;
	background: linear-gradient(to bottom, #b47e31, #ffbe4e, #ffefc7);
	touch-action: manipulation;
	// -webkit-text-size-adjust: none;
	*::-webkit-scrollbar {
		display: none;
	}
}

.page_tips {
	width: 100%;
	height: 100%;
	background: #ababab;
	position: fixed;
	top: 0;
	left: 0;
	display: none;
	z-index: 1001;

	p {
		font-size: 16px;
		color: #ffffff;
		margin-top: 5px;
		text-align: center;
		// font-family: "Avenir";

		&:first-child {
			margin-top: 120px;
		}
	}
}
</style>
