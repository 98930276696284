<template>
	<div class="book" :style="{ height: clientHeight }">
		<img class="pc_imgback" src="../../assets/images/web/activity_pic-bg.png" />
		<div class="title" @click="show">建廟沿革</div>
		<div class="booksWrap">
			<div class="buttonrow">
				<div
					v-for="item in button"
					:key="item.id"
					:class="item.isActive ? 'activeClass' : 'commonClass'"
					@click="handleChooseBtn(item)"
				>
					{{ item.label }}
				</div>
			</div>
			<div class="bookview">
				<Flipbook
					class="flipbook"
					:pages="pages"
					:clickToZoom="false"
					forwardDirection="left"
					:startPage="startPage"
					:singlePage="true"
					:nPolygons="20"
					ref="flipbook"
					v-slot="flipbook"
					@flip-left-start="onFlipLeftStart"
					@flip-right-start="onFlipRightStart"
				>
					<div class="progress">
						<el-progress
							:percentage="Number(flipbook.page / flipbook.numPages) * 100"
							:show-text="false"
							color="#474693"
						/>
					</div>
				</Flipbook>
			</div>
			<div class="buttonrow" style="margin-bottom: 26px">
				<div
					v-for="item in buttonBottom"
					:key="item.id"
					:class="item.isActive ? 'activeClass' : 'commonClass'"
					@click="handleChooseBtnSize(item)"
				>
					{{ item.label }}
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, reactive, getCurrentInstance, watch } from "vue";
import Flipbook from "flipbook-vue";
const { proxy } = getCurrentInstance();

const pages = ref([]);
const sizeType = ref(false);
const bigpages = ref([]);
const smallpages = ref([]);
const startPage = ref(1);
const clientHeight = ref(0);
clientHeight.value = document.body.clientHeight - 238 + "px";
const button = reactive([
	{
		id: 1,
		label: "壹",
		isActive: false,
	},
	{
		id: 2,
		label: "貳",
		isActive: false,
	},
	{
		id: 3,
		label: "參",
		isActive: false,
	},
]);
const buttonBottom = reactive([
	{
		id: 1,
		label: "大",
		isActive: false,
	},
	{
		id: 2,
		label: "小",
		isActive: true,
	},
]);
const percentage = ref(0);

onMounted(() => {
	let biglist = [require("../../assets/images/web/pcbigbook/post.jpg")],
		smalllist = [require("../../assets/images/web/pcbigbook/post.jpg")];
	for (let i = 1; i <= 41; i++) {
		biglist.push(require(`../../assets/images/book/big/m/m${Number(i)}.jpg`));
		bigpages.value = biglist;
	}
	for (let i = 1; i <= 20; i++) {
		smalllist.push(require(`../../assets/images/book/small/m/m${Number(i)}.jpg`));
		smallpages.value = smalllist;
	}
	pages.value = smalllist;
});
const handleChooseBtn = (ite) => {
	if (ite.isActive) {
		return;
	}
	if (ite.id == 1) {
		if (sizeType.value) startPage.value = 2;
		else startPage.value = 2;
	} else if (ite.id == 2) {
		if (sizeType.value) startPage.value = 18;
		else startPage.value = 10;
	} else if (ite.id == 3) {
		if (sizeType.value) startPage.value = 41;
		else startPage.value = 20;
	}
	button.map((item) => {
		if (item.id == ite.id) {
			item.isActive = true;
		} else {
			item.isActive = false;
		}
	});
};
const handleChooseBtnSize = (ite) => {
	if (ite.isActive) {
		return;
	}
	startPage.value = undefined;
	setTimeout(() => {
		startPage.value = 1;
	});
	button[0].isActive = false;
	button[1].isActive = false;
	button[2].isActive = false;
	if (ite.id == 1) {
		//大
		sizeType.value = true;
		pages.value = bigpages.value;
	} else if (ite.id == 2) {
		//小
		sizeType.value = false;
		pages.value = smallpages.value;
	}
	buttonBottom.map((item) => {
		if (item.id == ite.id) {
			item.isActive = true;
		} else {
			item.isActive = false;
		}
	});
};
const onFlipLeftStart = (page) => {
	JudegPage(page);
};
const onFlipRightStart = (page) => {
	JudegPage(Number(page) - 2);
};
const JudegPage = (page) => {
	if (sizeType.value) {
		//大
		if (page >= 1 && page < 17) {
			button[0].isActive = true;
			button[1].isActive = false;
			button[2].isActive = false;
		} else if (page >= 17 && page < 40) {
			button[1].isActive = true;
			button[0].isActive = false;
			button[2].isActive = false;
		} else if (page >= 40) {
			button[2].isActive = true;
			button[0].isActive = false;
			button[1].isActive = false;
		} else {
			button[2].isActive = false;
			button[0].isActive = false;
			button[1].isActive = false;
		}
	} else {
		if (page >= 1 && page < 9) {
			button[0].isActive = true;
			button[1].isActive = false;
			button[2].isActive = false;
		} else if (page >= 9 && page < 19) {
			button[1].isActive = true;
			button[0].isActive = false;
			button[2].isActive = false;
		} else if (page >= 19) {
			button[2].isActive = true;
			button[0].isActive = false;
			button[1].isActive = false;
		} else {
			button[2].isActive = false;
			button[0].isActive = false;
			button[1].isActive = false;
		}
	}
};
</script>

<style lang="less" scoped>
.book {
	padding: 26px 8px 60px 8px;
	box-sizing: border-box;
	overflow: auto;
	height: calc(100vh - 238px);
	overflow-x: hidden;
	.title {
		font-size: 20px;
		color: #ffffff;
		font-weight: bold;
	}
	.booksWrap {
		background-color: rgba(183, 128, 50, 0.3);
		backdrop-filter: blur(10px);
		margin-top: 8px;
		padding: 16px 56px 8px 56px;
		border-radius: 8px;
		.buttonrow {
			display: flex;
			align-items: center;
			justify-content: center;
			> div {
				margin-right: 6.4px;
			}
			.commonClass {
				font-size: 16px;
				font-weight: bold;
				padding: 4.5px 8px;
				background: #ffffff;
				color: #474693;
				border-radius: 3.2px;
			}
			.activeClass {
				font-size: 16px;
				font-weight: bold;
				padding: 4.5px 8px;
				background: #474693;
				color: #b4b3e4;
				border-radius: 3.2px;
			}
		}
		.bookview {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
		}
		.flipbook {
			width: 297px;
			height: 400px;
		}
		.progress {
			position: absolute;
			z-index: 10;
			width: 100%;
			bottom: -48px;
		}
	}
}
.pc_imgback {
	width: 100vw;
	position: fixed;
	bottom: 70px;
	left: 0px;
}
</style>
