<template>
	<div class="global_back">
		<TopHeader />
		<router-view v-slot="{ Component }">
			<keep-alive>
				<component :is="Component" :key="route.name" v-if="route.meta.keepAlive"></component>
			</keep-alive>
			<component :is="Component" :key="route.name" v-if="!route.meta.keepAlive"></component>
		</router-view>
		<Footer />
	</div>
</template>
<script setup>
import { useRoute } from "vue-router";
const route = useRoute();
console.log(route.meta.keepAlive);
</script>

<style lang="less" scoped>
.global_back {
	height: 100%;
	overflow: auto;
	overflow-x: hidden;
}
</style>
