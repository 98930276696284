<template>
	<div>
		<div class="top_header_plate center_flex_layout" style="justify-content: space-between">
			<img
				class="top_header_logo"
				@click="backHome"
				src="../../assets/images/web/zishenggong_logo.png"
			/>

			<div
				v-if="!typeIsMobile"
				class="center_flex_layout"
				style="justify-content: flex-start; margin-right: 59px"
			>
				<p
					v-for="(tabs, index) in tabArrayItem"
					:key="index"
					class="top_header_tab"
					:class="tabIndex === index ? 'current_tab' : ''"
					@click="pageRouter(index)"
				>
					{{ tabs }}
				</p>
			</div>

			<div v-else class="mobile_right_menu" @click="mobileMenuFn('open')">
				<img
					style="width: 100%; height: 100%"
					src="../../assets/images/mobile/mobile_menu_icon.png"
				/>
			</div>
		</div>

		<div :style="{ height: !typeIsMobile ? '102px' : '120px' }"></div>

		<div
			v-if="typeIsMobile"
			class="mobile_menu_plate"
			:class="showOpenMenu ? 'transform_mobile_menu' : ''"
		>
			<div
				class="top_header_plate center_flex_layout"
				style="justify-content: space-between; background: none"
			>
				<img class="top_header_logo" src="../../assets/images/web/zishenggong_logo.png" />

				<div class="mobile_right_menu" @click="mobileMenuFn('close')">
					<img
						style="width: 100%; height: 100%"
						src="../../assets/images/mobile/mobile_menu_close_icon.png"
					/>
				</div>
			</div>

			<div style="margin-top: 144px">
				<div
					class="center_flex_layout"
					style="justify-content: flex-start"
					v-for="(leftTitle, index) in tabArrayItem"
					:key="{ index }"
					:style="{ marginTop: index === 0 ? '0px' : '24px' }"
				>
					<div
						class="center_flex_layout"
						style="justify-content: flex-start"
						@click="pageRouter(index)"
					>
						<img
							style="width: 16px; height: 16px; margin-left: 8px"
							:style="{ opacity: tabIndex === index ? 1 : 0 }"
							src="../../assets/images/web/arrow-right-thick.png"
						/>

						<p style="font-size: 16px; color: #ffffff; font-family: NotoSerifTC-Black">
							{{ leftTitle }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { defineProps, ref, reactive, onMounted, getCurrentInstance, watch } from "vue";
const { proxy } = getCurrentInstance();
import { useRouter } from "vue-router";
const typeIsMobile = ref(false);
const tabArrayItem = ref(["最新活動", "建廟沿革", "師父來惹", "活動花絮", "服務項目"]);
const tabIndex = ref(undefined);
const showOpenMenu = ref(false);
const router = useRouter();
onMounted(() => {
	typeIsMobile.value = proxy.$mobile() ? true : false;
});

watch(
	() => router.currentRoute.value,
	(newValue) => {
		// console.log(newValue.name);
		if (newValue.name === "outtakes" || newValue.name === "outakesDetail") {
			tabIndex.value = 3;
		} else if (newValue.name === "newEvents") {
			tabIndex.value = 0;
		} else if (newValue.name === "shifuCome") {
			tabIndex.value = 2;
		} else if (newValue.name === "jianMiao") {
			tabIndex.value = 1;
		} else if (newValue.name === "serviceProject") {
			tabIndex.value = 4;
		} else {
			tabIndex.value = "";
		}
	},
	{ immediate: true }
);

const pageRouter = (index) => {
	const pagePath = ["newEvents", "jianMiao", "shifuCome", "outtakes", "serviceProject"];
	tabIndex.value = index;

	router.push(pagePath[index]);

	if (showOpenMenu.value) {
		showOpenMenu.value = false;
	}
};

const backHome = () => {
	tabIndex.value = "";
	router.push("/");

	if (showOpenMenu.value) {
		showOpenMenu.value = false;
	}
};

const mobileMenuFn = (type) => {
	if (type === "open") {
		showOpenMenu.value = true;
	} else {
		showOpenMenu.value = false;
	}
};
</script>

<style lang="less" scoped>
.top_header_plate {
	width: 100%;
	padding-top: 32px;
	position: fixed;
	top: 0;
	z-index: 999;
	background: linear-gradient(to bottom, rgba(214, 137, 28, 0.8), rgba(236, 139, 0, 0));

	@media screen and (max-width: 500px) {
		padding-top: 56px;
		padding-bottom: 16px;
	}

	.top_header_logo {
		width: 254px;
		height: 70px;
		margin-left: 59px;
		cursor: pointer;

		@media screen and (max-width: 500px) {
			width: 174px;
			height: 48px;
			margin-left: 16px;
		}
	}

	.top_header_tab {
		font-size: 20px;
		color: #ffffff;
		font-weight: bolder;
		margin-left: 44px;
		position: relative;
		cursor: pointer;

		&:before {
			content: "";
			width: 0;
			height: 2px;
			background-color: #ffffff;
			position: absolute;
			bottom: -5px;
			transition: all linear 0.2s;
		}

		&:first-child {
			margin-left: 0;
		}

		&:hover {
			&:before {
				width: 100%;
			}
		}
	}

	.current_tab {
		&:before {
			width: 100%;
		}
	}
}

.mobile_menu_plate {
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.8);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1001;
	transition: all linear 0.2s;
	transform: translateX(-100vw);
}

.transform_mobile_menu {
	transition: all linear 0.2s;
	transform: translateY(0);
}
.mobile_right_menu {
	width: 40px;
	height: 40px;
	margin-right: 16px;
}
</style>
