export const datalist = [
	{
		id: 1,
		year: 2,
		month: "一月",
		date: "2023-01-13",
		applyName: "《2022年末因果法會》",
		content: {
			titleone: "2023-01/13（農曆12/22）",
			titleTwo: "《2022年末因果法會》",
			titleThree: "解冤親因果、超拔祖先、嬰靈、牲靈、贊普、地基主等項目。",
			titleFour: "",
			contentlist: [],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 2,
		year: 2,
		month: "一月",
		date: "2023-01-15",
		applyName: "《清囤、送神》",
		content: {
			titleone: "2023-01/15（農曆12/24）",
			titleTwo: "《清囤、送神》",
			titleThree:
				"在送神這一天謝謝值年太歲保佑一年光彩，將本宮裡所有燈種（三運合燈、光明燈、太歲燈、文昌智慧燈、桃花貴人燈、財神燈、藥鼎燈、宅利燈）熄燈。",
			titleFour: "",
			contentlist: [],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 3,
		year: 2,
		month: "一月",
		date: "2023-01-22",
		applyName: "《玉清元始天尊聖誕》",
		content: {
			titleone: "2023-01/22（農曆1/1）",
			titleTwo: "《玉清元始天尊聖誕》",
			titleThree: "本宮祝壽大典時間：2023-01-21晚上23:00",
			titleFour: "玉清元始天尊",
			contentlist: [
				"元始天尊是世界的最高神，宇宙萬有之源，道的化身，至高無上，與道同體，也是道經的演說者，道教教主。《靈寶經》中，元始天尊磅礴於天地之間，開天闢地，在天地初開時傳授道經，稱為「開劫度人」。元始天尊與靈寶天尊、道德天尊合稱「三清」。在道教宮觀三清殿，其塑像居於三清中位，常以手持混元寶珠形象。",
			],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 4,
		year: 2,
		month: "一月",
		date: "2023-01-25",
		applyName: "《點燈、迎太歲》",
		content: {
			titleone: "2023-01/25（農曆1/4）",
			titleTwo: "《點燈、迎太歲》",
			titleThree:
				"初四恭迎值年太歲保佑來年光彩，將本宮裡所有燈種（三運合燈、光明燈、太歲燈、文昌智慧燈、桃花貴人燈、財神燈、藥鼎燈、宅利燈）點起，祈求眾神護佑來年鴻運。",
			titleFour: "",
			contentlist: [],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 5,
		year: 2,
		month: "一月",
		date: "2023-01-30",
		applyName: "《玉皇上帝聖誕》",
		content: {
			titleone: "2023-01/30（農曆1/9）",
			titleTwo: "《玉皇上帝聖誕》",
			titleThree: "本宮祝壽大典時間：2023-01-29晚上23:00",
			titleFour: "玉皇上帝",
			contentlist: [
				"尊稱「昊天金闕無上至尊自然妙有彌羅至真玉皇赦罪賜福大天尊玄穹高上帝」，通稱玉皇大天尊、玄穹高上帝，簡稱玉皇、玉帝，俗稱天公伯、天公，宋代尊稱之為昊天玄穹玉皇上帝，為道教神界統治者、先天尊神、六御之首，源自中國自古以來信仰的昊天上帝。",
			],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 6,
		year: 2,
		month: "二月",
		date: "2023-02-04",
		applyName: "《初春十二生肖祭解、補運補財》",
		content: {
			titleone: "2023-02/04（農曆1/14）",
			titleTwo: "《初春十二生肖祭解、補運補財》",
			titleThree: "",
			titleFour: "",
			contentlist: [],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 7,
		year: 2,
		month: "二月",
		date: "2023-02-05",
		applyName: "《上元天官大帝聖誕》",
		content: {
			titleone: "2023-02/04（農曆1/15）",
			titleTwo: "《上元天官大帝聖誕》",
			titleThree: "本宮祝壽大典時間：2023-02-04晚上23:00",
			titleFour: "上元天官大帝",
			contentlist: [
				"上元天官大帝，全稱上元一品九炁賜福天官曜靈元陽大帝紫微帝君，亦稱為天官、紫微大帝、元陽大帝、上元一品賜福天官紫微大帝，是道教中三官大帝之一，掌管諸天帝王、上聖大神。傳說「天官賜福」，校定人之罪福，許多廟宇會在天官大帝聖誕之日，舉行祈福法事，希冀能集福消災，亦是補財庫好時機，意指補年頭運可旺整個上半年的財運。",
			],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 8,
		year: 2,
		month: "二月",
		date: "2023-02-21",
		applyName: "《濟公師父、降龍師父、福德正神聖誕》",
		content: {
			titleone: "2023-02/21（農曆2/2）",
			titleTwo: "《濟公師父、降龍師父、福德正神聖誕》",
			titleThree: "本宮祝壽大典時間： 2023-02-20晚上23:00",
			titleFour: "濟公師父、降龍師父",
			titleFive: "福德正神",
			contentlist: [
				"民間相傳降龍尊者是佛祖的第十八個弟子，古達印度龍王用水淹那竭國，而且私自藏匿了佛經，降龍尊者制服了龍王取回佛經，立了大功，所以封他做了降龍羅漢。但他修煉了幾百年都不能成就成果，於是他請教觀音菩薩，才知道知己塵緣未了，於是下凡間普渡眾生了卻塵緣，下凡之後就是濟公了。",
				"濟公，又稱濟癲和尚、濟公活佛、濟公禪師，俗名李修緣。濟公21歲出家，法號「道濟」，拜靈隱寺住持遠瞎堂為師，當時寺內眾僧深惡道濟瘋顛痴狂而意欲排擠，遠瞎堂開示云：「禪門廣大，豈不容一顛僧？」，此後，滿寺僧人都稱他為「濟顛」。",
				"濟顛雖然「酒肉當食，不守戒律」，但因仗義勇為，專掃人間不平事，因此百姓視為「再世活佛」，尊敬有加，故也稱其為「濟公」。",
				"本宮濟公師父為睜一隻眼閉一隻眼的法相：『反求諸己，無愧人生。』-人生要爭的事情太多，究竟是為了事理而爭抑或是為了你的顏面心緒而爭。睜一隻眼閉一隻眼，凡事若不傷身，能過就寬大心為而釋過吧！有些心緒兩隻眼睛看得太清楚反而容易放不下而固執，不如少隻眼，簡單一些！",
				"本宮降龍師父塑像手持羽扇、葫蘆，腳踩龍。",
			],
			contentlist2: [
				"土地公的正稱是「福德正神」，是管理土地之神。民間以其造福鄉里，德澤萬民，所以尊稱為「福德正神」。有后土、土正、社神、社公、土地、土伯各稱呼。",
				"土地公的信仰源於古代對土地的崇拜，屬於自然神祇之一。中國自古以農立國，因此對土地的重視自然不在話下，人們以為有土地才能夠生長五穀，有了五穀方能養活人類。所以對於土地漸漸的發生感謝之念，後來便把土地視為神明了。",
				"隨著時代背景的變遷而逐漸改變，土地公不僅是農人祭拜的神，由於祂能使農人致富，而轉化為「財神」，目前的商業以及金融、建築業者，也都祭祀土地公，土地公的信仰意識，由司掌土地之神，而兼職財神，成為現代社會各階層人士普偏奉拜的神明。",
			],
			url: "",
			imgurlList: [require("../../assets/images/web/activity/22.jpg")],
			imgurlList2: [require("../../assets/images/web/activity/23.jpg")],
		},
	},
	{
		id: 9,
		year: 2,
		month: "三月",
		date: "2023-03-06",
		applyName: "《太清道德天尊、九天玄女娘娘聖誕》",
		content: {
			titleone: "2023-03/06（農曆2/15）",
			titleTwo: "《太清道德天尊、九天玄女娘娘聖誕》",
			titleThree: "本宮祝壽大典時間：2023-03-05晚上23:00",
			titleFour: "太清道德天尊",
			titleFive: "九天玄女娘娘",
			contentlist: [
				"又稱「太上老君」，道教相信道家哲人老子是老君的化身，度人無數，因其傳下道家經典《道德經》，故稱老君為道德天尊，也被道教奉為開山祖師。太上老君是三清尊神中受到最多香火奉祀的神明，在道教宮觀三清殿，其塑像居於三清虎邊，手執蒲扇或日月寶扇。",
			],
			contentlist2: [
				"九天玄女又名「元女」、「九天娘娘」，是代表正義之神，傳說授黃帝兵符印劍，協助黃帝制服蚩尤平定四方，因擅長兵法戰術的緣故，在台灣常有九天玄女手持寶劍、葫蘆等法器的尊像。",
				"九天玄女主要掌管風水、香氛、孩子美貌智慧以及姻緣。其中掌管香氛的原因，即是傳聞九天玄女是發明「香」的始祖，但功用並非現在禮佛敬神的供品，而是治療疾病的藥品，因此九天玄女被稱為「香媽」，是製香業者非拜不可的守護神。",
			],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 10,
		year: 2,
		month: "三月",
		date: "2023-03-10",
		applyName: "《觀世音菩薩聖誕》",
		content: {
			titleone: "2023-03/10（農曆2/19）",
			titleTwo: "《觀世音菩薩聖誕》",
			titleThree: "本宮祝壽大典時間： 2023-03-09晚上23:00",
			titleFour: "觀世音菩薩",
			contentlist: [
				"觀世音信仰源出於佛教，最初原是男性人物，隨著《普門品》流傳尋救苦、慈悲應化的力，觀音深入民心，逐漸轉變成女神，其信仰的普及與妙善公主的傳說亦有關，觀音經由轉世化身的信仰，以多種女性的身份參與人類世界。",
				"「觀世音菩薩」從字面解釋就是「觀察世間聲音」的菩薩，出自《妙法蓮華經》之《觀世音菩薩普門品》：「若有無量百千萬億眾生，受諸苦惱，聞是觀世音菩薩，一心稱名，觀世音菩薩即時觀其音聲，皆得解脫。」",
				"不論在佛教或者民間，觀世音菩薩都被奉為慈悲為懷、救苦救難、有求必應的菩薩，有「眾生的仁慈保護者」之稱。因此不少民眾不論是求官、求子、求健康、求平安皆會向觀世音菩薩祈願保佑。",
			],
			url: "",
			imgurlList: [require("../../assets/images/web/activity/19.jpg")],
		},
	},
	{
		id: 11,
		year: 2,
		month: "三月",
		date: "2023-03-11",
		applyName: "《三月濟世 - 祭改、補財庫》",
		content: {
			titleone: "2023-03/11（農曆2/20）",
			titleTwo: "《三月濟世 - 祭改、補財庫》",
			titleThree: "祭改、收驚、補財庫、補運、淨車等儀式",
			titleFour: "",
			contentlist: [],
			url: "",
			imgurlList: [require("../../assets/images/web/activity/20.jpg")],
		},
	},
	{
		id: 12,
		year: 2,
		month: "三月",
		date: "2023-03-12",
		applyName: "《三月濟世 - 問事》",
		content: {
			titleone: "2023-03/12（農曆2/21）",
			titleTwo: "《三月濟世 - 問事》",
			titleThree: "現場問事、國際問事",
			titleFour: "",
			contentlist: [],
			url: "",
			imgurlList: [require("../../assets/images/web/activity/21.jpg")],
		},
	},
	{
		id: 13,
		year: 2,
		month: "四月",
		date: "2023-04-08",
		applyName: "《四月濟世 - 祭改、補財庫》",
		content: {
			titleone: "2023-04/08（農曆2/18）",
			titleTwo: "《四月濟世 - 祭改、補財庫》",
			titleThree: "祭改、收驚、補財庫、補運、淨車等儀式",
			titleFour: "",
			contentlist: [],
			url: "",
			imgurlList: [require("../../assets/images/web/activity/16.jpg")],
		},
	},
	{
		id: 14,
		year: 2,
		month: "四月",
		date: "2023-04-09",
		applyName: "《四月濟世 - 問事》",
		content: {
			titleone: "2023-04/09（農曆2/19）",
			titleTwo: "《四月濟世 - 問事》",
			titleThree: "現場問事、國際問事",
			titleFour: "",
			contentlist: [],
			url: "",
			imgurlList: [require("../../assets/images/web/activity/17.jpg")],
		},
	},
	{
		id: 15,
		year: 2,
		month: "四月",
		date: "2023-04-22",
		applyName: "《玄天上帝聖誕》",
		content: {
			titleone: "2023-04/22（農曆3/3）",
			titleTwo: "《玄天上帝聖誕》",
			titleThree: "本宮祝壽大典時間：2023-04-21晚上23:00",
			titleFour: "玄天上帝",
			contentlist: [
				"北極玄天上帝又稱「真武大帝、玄武大帝、北極大帝」，民間親稱「上帝爺公、上帝公、帝爺公」，源自遠古「天之四靈 - 北方玄武」的星宿崇拜。",
				"《太上說玄天大聖真武本傳神呪妙經》、《元始天尊說北方真武妙經》、《玄天上帝啟聖錄》為主的二經一傳，闡述「昔有淨樂國王與善勝皇后，夢吞日光，覺而有娠，懷胎一十四月，開皇元年三月三日午時，誕生於王宮」闡述玄天上帝從「先天始炁」的星宿之神降生到人間，歷經凡身修練，證得無上大道；又以「披髮跣足，踏騰蛇八卦神龜，部領三十萬神將、六丁六甲、五雷神兵」之姿，奉元始天尊之命下凡收斷天下妖魔，而受封為玄天上帝的伏魔帝格。",
				"玄天上帝塑像通常威風凜凜，八面生風；披髮黑衣；右手執七星保劍；左手印訣，一指向天，則表圓道之意；跣足，左踏龜，右踏蛇；而隨從將帥亦都執黑旗。",
			],
			url: "",
			imgurlList: [require("../../assets/images/web/activity/18.jpg")],
		},
	},
	{
		id: 16,
		year: 2,
		month: "五月",
		date: "2023-05-04",
		applyName: "《天官武財神聖誕》",
		content: {
			titleone: "2023-05-04（農曆3/15）",
			titleTwo: "《天官武財神聖誕》",
			titleThree: "本宮祝壽大典時間：2023-05-03晚上23:00",
			titleFour: "天官武財神",
			contentlist: [
				"民間相傳趙公明為「武財神」，居五路財神之首，據封神演義記載全稱金龍如意正一龍虎玄壇真君，因其統領「進寶天尊蕭升」、「納珍天尊曹寶」、「招財使者陳九公」、「利市仙官姚少司」四位神仙，四仙專司迎祥納福、商賈買賣，故成為財神。",
				"本宮武財神於2015年12月27日安座，正殿金紙區設有補財補庫發財金，祈求武財神賜財方式如下：填寫紅色疏文上資料 →蓋上紅印泥指印 →點香稟告姓名、職業、生日、居住地 →期許的客戶來源方向。",
			],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 17,
		year: 2,
		month: "五月",
		date: "2023-05-12",
		applyName: "《國母娘娘、天上聖母聖誕》",
		content: {
			titleone: "2023-05-12（農曆3/23）",
			titleTwo: "《國母娘娘、天上聖母聖誕》",
			titleThree: "本宮祝壽大典時間： 2023-05-11晚上23:00",
			titleFour: "國母娘娘",
			titleFive: "天上聖母",
			contentlist: ["本宮所供奉的國母娘娘為天上聖母的母親。"],
			contentlist2: [
				"媽祖是台灣重要的民間信仰，媽祖原名林默，生前聰慧過人、沈默寡言，是一位釋道兼修的仙姑，為人占卜禍福為事，羽化之後，傳言她常以一位「穿紅衣的長髮素顏美女形象」在海浪風湧時顯靈、能使颱風轉彎、保祐平安航行，沿海船員與漁民對她的崇拜逐漸形成信仰，尊其為「護國庇民」的海洋女神。",
				"媽祖塑像的臉部主要有粉、黑、金三種顏色，皆代表不同意義。粉面媽祖跟凡人一樣，擁有粉嫩的皮膚，象徵28歲飛升成仙的青春面容；金色是神明的色彩，金面媽祖象徵媽祖成仙得道，金光閃閃的意思；台灣媽祖神像則以黑面最多，其意義有兩種說法，一種是取媽祖公正凶悍、救苦救難的形象、另一種是信眾長期奉祀薰黑而成，又稱「香煙媽」，代表香火鼎盛、靈力強大。本宮媽祖娘娘為東石笨港口港口宮分靈，為黑面三媽。",
			],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 18,
		year: 2,
		month: "五月",
		date: "2023-05-13",
		applyName: "《五月濟世 - 祭改、補財庫》",
		content: {
			titleone: "2023-05/13（農曆3/24）",
			titleTwo: "《五月濟世 - 祭改、補財庫》",
			titleThree: "祭改、收驚、補財庫、補運、淨車等儀式",
			titleFour: "",
			contentlist: [],
			url: "",
			imgurlList: [require("../../assets/images/web/activity/15.jpg")],
		},
	},
	{
		id: 19,
		year: 2,
		month: "五月",
		date: "2023-05-14",
		applyName: "《五月濟世 - 問事》",
		content: {
			titleone: "2023-05/14（農曆3/25）",
			titleTwo: "《五月濟世 - 問事》",
			titleThree: "現場問事、國際問事",
			titleFour: "",
			contentlist: [],
			url: "",
			imgurlList: [require("../../assets/images/web/activity/14.jpg")],
		},
	},
	{
		id: 20,
		year: 2,
		month: "六月",
		date: "2023-06-10",
		applyName: "《六月濟世 - 祭改、補財庫》",
		content: {
			titleone: "2023-06-10（農曆4/23）",
			titleTwo: "《六月濟世 - 祭改、補財庫》",
			titleThree: "祭改、收驚、補財庫、補運、淨車等儀式",
			titleFour: "",
			contentlist: [],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 21,
		year: 2,
		month: "六月",
		date: "2023-06-11",
		applyName: "《六月濟世 - 問事》",
		content: {
			titleone: "2023-06-11（農曆4/24）",
			titleTwo: "《六月濟世 - 問事》",
			titleThree: "現場問事、國際問事",
			titleFour: "",
			contentlist: [],
			url: "",
			imgurlList: [require("../../assets/images/web/activity/13.jpg")],
		},
	},
	{
		id: 22,
		year: 2,
		month: "六月",
		date: "2023-06-21",
		applyName: "《上清靈寶天尊聖誕》",
		content: {
			titleone: "2023-06-21（農曆5/4）",
			titleTwo: "《上清靈寶天尊聖誕》",
			titleThree: "本宮祝壽大典時間： 2023-07-20晚上23:00",
			titleFour: "上清靈寶天尊",
			contentlist: [
				"靈寶天尊又稱「上清大帝」，道教天神，最早為道教上清派尊神，三清尊神之第二位。專門講述道教經書，是《道經》的象徵，與元始天尊出自一元，非元始天尊弟子，在道觀之三清殿中，其塑像居居於三清龍邊，大多手捧玉如意或太極圖。天尊的神誕之日是夏至。",
			],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 23,
		year: 2,
		month: "七月",
		date: "2023-07-15",
		applyName: "《七月濟世 - 祭改、補財庫》",
		content: {
			titleone: "2023-07-15（農曆5/28）",
			titleTwo: "《七月濟世 - 祭改、補財庫》",
			titleThree: "祭改、收驚、補財庫、補運、淨車等儀式",
			titleFour: "",
			contentlist: [],
			url: "",
			imgurlList: [require("../../assets/images/web/activity/10.jpg")],
		},
	},
	{
		id: 24,
		year: 2,
		month: "七月",
		date: "2023-07-16",
		applyName: "《七月濟世 - 問事》",
		content: {
			titleone: "2023-07-16（農曆5/29）",
			titleTwo: "《七月濟世 - 問事》",
			titleThree: "現場問事、國際問事",
			titleFour: "",
			contentlist: [],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 25,
		year: 2,
		month: "七月",
		date: "2023-07-23",
		applyName: "《下壇將軍聖誕》",
		content: {
			titleone: "2023-07-23（農曆6/6）",
			titleTwo: "《下壇將軍聖誕》",
			titleThree: "本宮祝壽大典時間：2023-07-22晚上23:00",
			titleFour: "下壇將軍",
			contentlist: [
				"虎爺是道教中一種以虎為形象的神祇，俗稱虎爺公、虎爺將軍、虎將軍，尊稱為下壇將軍，其中虎爺也有分成黑毛與黃毛兩種。多數廟宇都可見到祂的身影，一般供奉於神桌或主神神位下方，因小朋友視線容易看到，也被視為「兒童的守護神」。祭拜虎爺可以生雞蛋為供品。",
			],
			url: "",
			imgurlList: [require("../../assets/images/web/activity/11.jpg")],
		},
	},
	{
		id: 26,
		year: 2,
		month: "七月",
		date: "2023-07-30",
		applyName: "《驪山母娘聖誕》",
		content: {
			titleone: "2023-07-30（農曆6/13）",
			titleTwo: "《驪山母娘聖誕》",
			titleThree: "本宮祝壽大典時間： 2023-07-29晚上23:00",
			titleFour: "驪山母娘",
			contentlist: [
				"驪山老母是道教供奉祭祀的一位遠古尊神，在中國民間具有很大的影響力，多為指點迷津、傳授秘籍而受到世人所崇奉。《漢書 ·律曆志》將驪山老母稱為驪山女，因其生活在驪山一帶之故。驪山乃老母煉石補天之座騎奉命而化之，腹有泉，出溫湯，供人民沐浴，能醫治多種皮膚頑症，故曰神湯，亦是驪山老母之聖德，亦有另一種說法為驪山母娘習以煉丹之名。",
				"本宮驪山母娘法相為手抱丹爐之姿。",
			],
			url: "",
			imgurlList: [require("../../assets/images/web/activity/12.jpg")],
		},
	},
	{
		id: 27,
		year: 2,
		month: "八月",
		date: "2023-08-02",
		applyName: "《豁落靈官王天君聖誕》",
		content: {
			titleone: "2023-08-02（農曆6/16）",
			titleTwo: "《豁落靈官王天君聖誕》",
			titleThree: "本宮祝壽大典時間：2023-08-01晚上23:00",
			titleFour: "豁落靈官王天君",
			contentlist: [
				"王天君為道教護法神三十六帥之首，也是十二雷門之一，是道教鎮守山門之神，雷部、火部天將及護法神，為道教諸多護法神中最受崇奉者之一，相當於佛門護法神韋馱菩薩。正式名稱為「豁落靈官雷聲應化天尊」，信徒習以「王天君」、「王恩主」、「都天糾察應化王」來尊稱。王天君法像，通常有三眼，其額間又有一目，一手持金鞭，一手掐「靈官訣」，或稱為「通天指」，意指可以上達天聽本宮王天君目前以法器形象為供奉，預計今年進行王天君神像雕刻與安座。",
			],
			url: "",
			imgurlList: [require("../../assets/images/web/activity/1.jpg")],
		},
	},
	{
		id: 28,
		year: 2,
		month: "八月",
		date: "2023-08-05",
		applyName: "《觀音佛祖成道紀念日》",
		content: {
			titleone: "2023-08-05（農曆6/19）",
			titleTwo: "《觀音佛祖成道紀念日》",
			titleThree: "本宮祝壽大典時間：2023-08-04晚上23:00",
			titleFour: "觀音佛祖成道",
			contentlist: [
				"觀世音信仰源出於佛教，最初原是男性人物，隨著《普門品》流傳尋救苦、慈悲應化的力，觀音深入民心，逐漸轉變成女神，其信仰的普及與妙善公主的傳說亦有關，觀音經由轉世化身的信仰，以多種女性的身份參與人類世界。",
				"「觀世音菩薩」從字面解釋就是「觀察世間聲音」的菩薩，出自《妙法蓮華經》之《觀世音菩薩普門品》：「若有無量百千萬億眾生，受諸苦惱，聞是觀世音菩薩，一心稱名，觀世音菩薩即時觀其音聲，皆得解脫。」",
				"不論在佛教或者民間，觀世音菩薩都被奉為慈悲為懷、救苦救難、有求必應的菩薩，有「眾生的仁慈保護者」之稱。因此不少民眾不論是求官、求子、求健康、求平安皆會向觀世音菩薩祈願保佑。",
			],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 29,
		year: 2,
		month: "八月",
		date: "2023-08-10",
		applyName: "《關聖帝君聖誕》",
		content: {
			titleone: "2023-08-10（農曆6/24）",
			titleTwo: "《關聖帝君聖誕》",
			titleThree: "本宮祝壽大典時間：2023-08-09晚上23:00",
			titleFour: "關聖帝君",
			contentlist: [
				"關聖帝君是華人世界裡相當普遍的神祇，關聖帝君又稱協天大帝、文衡聖帝，關帝爺、帝君爺，關公、恩主公、恩主等，佛教稱為伽藍，即護法神之意。關聖帝君也是多種行業的行業神，包括讀書人的文昌神、軍警的戰神、商人的財神，乃至幫會的主神。",
				"民間信仰中，關公形象有分為文像與武像，並有不同的寓意。文關公不持刀，多拿著一本《春秋》，主學業、事業、官運、安康。武關公則是手持一把關刀，主避邪、擋煞氣、鎮宅、招正財、庇佑生意興隆等。",
			],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 30,
		year: 2,
		month: "八月",
		date: "2023-08-12",
		applyName: "《八月濟世 - 祭改、補財庫》",
		content: {
			titleone: "2023-08-12（農曆6/26）",
			titleTwo: "《八月濟世 - 祭改、補財庫》",
			titleThree: "祭改、收驚、補財庫、補運、淨車等儀式-08-09晚上23:00",
			titleFour: "",
			contentlist: [],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 31,
		year: 2,
		month: "八月",
		date: "2023-08-13",
		applyName: "《八月濟世 - 問事》",
		content: {
			titleone: "2023-08-13（農曆6/27）",
			titleTwo: "《八月濟世 - 問事》",
			titleThree: "現場問事、國際問事",
			titleFour: "",
			contentlist: [],
			url: "",
			imgurlList: [require("../../assets/images/web/activity/10.jpg")],
		},
	},
	{
		id: 32,
		year: 2,
		month: "八月",
		date: "2023-08-30",
		applyName: "《中元地官大帝聖誕》",
		content: {
			titleone: "2023-08-30（農曆7/15）",
			titleTwo: "《中元地官大帝聖誕》",
			titleThree: "本宮祝壽大典時間： 2023-08-29晚上23:00",
			titleFour: "中元地官大帝",
			contentlist: [
				"中元地官大帝，全稱中元二品七炁赦罪地官洞靈清虛大帝青靈帝君，亦稱為地官、清虛大帝、青靈帝君、中元二品赦罪地官清虛大帝，是道教中三官大帝之一，掌管五嶽大帝、五嶽諸真人及惜地神仙已得道者。傳說「地官赦罪」，校定世人善惡及赦罪之事，可於地官大帝聖誕當天，懺悔請求赦免人生在世無意中累積的小過小錯。",
			],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 33,
		year: 2,
		month: "九月",
		date: "2023-09-02",
		applyName: "《西王母娘娘聖誕》",
		content: {
			titleone: "2023-09-02（農曆7/18）",
			titleTwo: "《西王母娘娘聖誕》",
			titleThree: "本宮祝壽大典時間：2023-09-01晚上23:00",
			titleFour: "西王母娘娘",
			contentlist: [
				"西華王母娘娘：西王母，俗稱王母娘娘，又稱西姥、王母、金母和金母元君。全名為白玉龜台九靈太真金母元君、白玉龜台九鳳太真西王母或太靈九光龜台金母元君。",
				"道教和民間一直將西王母作為長壽的象徵，常向西王母祈願長壽、福祿、趨吉避凶、家族興旺、遠遊平安、婚嫁美滿、賜子等。",
			],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 34,
		year: 2,
		month: "九月",
		date: "2023-09-10",
		applyName: "《無極紫勝宮 清願普渡法會》",
		content: {
			titleone: "2023-09-10（農曆7/26）",
			titleTwo: "《無極紫勝宮 清願普渡法會》",
			titleThree: "",
			titleFour: "",
			contentlist: [
				"這次將在新廟地舉行 !!! 殊勝的第一次！",
				"普渡法會是一個充滿慈悲和善意的重要活動。在這個儀式中，我們以虔誠的心情追憶先人，並祈禱他們在來世得到安寧與智慧。",
				"普渡法會的核心價值是慈悲。我們以慈悲的心情面對生命的無常，對逝去的親人和眾生抱持著無私的關懷和愛心。",
				"透過法會的舉行，我們希望能為亡者獲得解脫，也帶給自己更深層次的心靈寄託。",
				"讓我們以慈悲和善意為引領，參與普渡法會，心靈得到淨化與滋養。",
				"這是一個機會，讓我們回歸內心的平靜，並向眾生散播慈悲和善意的種子。",
				"願這份慈悲和善意持續傳承下去，為所有眾生的福祉和和平作出貢獻。",
				"❁本次普渡法會參加項目：",
				"冤親債主 歷代祖先 單位指名祖先 無主贊普 嬰靈 寵物/牲靈 地基主",
			],
			url: "https://www.liqform.com/v/rve8z4kw",
			imgurlList: [
				require("../../assets/images/web/activity/3.jpg"),
				require("../../assets/images/web/activity/4.jpg"),
				require("../../assets/images/web/activity/5.jpg"),
			],
		},
	},
	{
		id: 35,
		year: 2,
		month: "十月",
		date: "2023-10-21",
		applyName: "《大南巡》",
		content: {
			titleone: "2023-10-21~2023-10-22（農曆9/7~8）",
			titleTwo: "《大南巡》",
			titleThree: "",
			titleFour: "",
			contentlist: [
				"經過了一年的休整，你們都好嗎？ 【 師父 想念你們了 】",
				"誠摯邀請大家踏上一年一度的兩天一夜旅程，共同傳承宮廟文化與神佛信仰的精髓。這趟旅程將帶領我們進入心靈的洗滌與沐浴。",
				"在這個繁忙的現代社會，我們常常忽略了內心的寧靜和平衡。透過這次旅程，我們將與古老的宮廟文化互動，感受神佛的智慧與慈悲。",
				"這是一個與自己對話、重新連結內在力量的機會。",
				"第一天：本宮出發✏ 南投埔里寶湖宮天地堂地母廟 ✏ 南投名間松柏嶺受天宮 ✏ 南化金光山厚德紫竹寺",
				"第二天：南化金光山厚德紫竹寺 ✏ 嘉義水上鄉奉天宮濟公廟 ✏ 嘉義新港溪北六興宮 ✏大溪 無極紫勝宮本宮",
			],
			url: "https://www.liqform.com/v/4bjr6xdk",
			imgurlList: [
				require("../../assets/images/web/activity/6.jpg"),
				require("../../assets/images/web/activity/7.jpg"),
			],
		},
	},
	{
		id: 36,
		year: 2,
		month: "十月",
		date: "2023-10-23",
		applyName: "《中壇元帥聖誕》",
		content: {
			titleone: "2023-10-23（農曆9/9）",
			titleTwo: "《中壇元帥聖誕》",
			titleThree: "本宮祝壽大典時間：2023-10-22晚上23:00",
			titleFour: "中壇元帥",
			contentlist: [
				"中壇元帥，全名為「中壇元帥威靈顯赫大將軍」，簡稱「中壇元帥」，也稱作「太子元帥」，民間暱稱「太子爺」或「三太子」，係玉皇上帝駕前的中營元帥，統領東西南北中五營的所有天兵天將，大多數的宮廟也奉祀中壇元帥來掌管五營兵將。",
				"早期臺灣村落如遇上難解的災厄或妖魔作祟，多會設壇祈求中壇元帥護佑，因此也成為與百姓親近的神明之一，為民間信仰的守護神。",
			],
			url: "",
			imgurlList: [require("../../assets/images/web/activity/8.jpg")],
		},
	},
	{
		id: 37,
		year: 2,
		month: "十月",
		date: "2023-10-29",
		applyName: "《無極紫勝宮宮慶活動》",
		content: {
			titleone: "2023-10-29（農曆9/15）",
			titleTwo: "《無極紫勝宮宮慶活動》",
			titleThree: "無極紫勝宮創立32週年紀念日宮慶活動：補運、補財庫",
			titleFour: "",
			contentlist: [""],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 38,
		year: 2,
		month: "十月",
		date: "2023-10-31",
		applyName: "《無極紫勝宮創立32週年紀念日》",
		content: {
			titleone: "2023-10-31（農曆9/17）",
			titleTwo: "《無極紫勝宮創立32週年紀念日》",
			titleThree: "",
			titleFour: "",
			contentlist: [""],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 39,
		year: 2,
		month: "十一月",
		date: "2023-11-02",
		applyName: " 《觀世音菩薩出家紀念日》",
		content: {
			titleone: "2023-11-02（農曆9/19）",
			titleTwo: " 《觀世音菩薩出家紀念日》",
			titleThree: "本宮團拜時間： 2023-11-01晚上23:00",
			titleFour: "觀世音菩薩",
			contentlist: [
				"觀世音信仰源出於佛教，最初原是男性人物，隨著《普門品》流傳尋救苦、慈悲應化的力，觀音深入民心，逐漸轉變成女神，其信仰的普及與妙善公主的傳說亦有關，觀音經由轉世化身的信仰，以多種女性的身份參與人類世界。",
				"「觀世音菩薩」從字面解釋就是「觀察世間聲音」的菩薩，出自《妙法蓮華經》之《觀世音菩薩普門品》：「若有無量百千萬億眾生，受諸苦惱，聞是觀世音菩薩，一心稱名，觀世音菩薩即時觀其音聲，皆得解脫。」",
				"不論在佛教或者民間，觀世音菩薩都被奉為慈悲為懷、救苦救難、有求必應的菩薩，有「眾生的仁慈保護者」之稱。因此不少民眾不論是求官、求子、求健康、求平安皆會向觀世音菩薩祈願保佑。",
			],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 40,
		year: 2,
		month: "十一月",
		date: "2023-11-27",
		applyName: " 《下元水官大帝聖誕》",
		content: {
			titleone: "2023-11-27（農曆10/15）",
			titleTwo: " 《下元水官大帝聖誕》",
			titleThree: "本宮祝壽大典時間： 2023-11-26晚上23:00",
			titleFour: "下元水官大帝",
			contentlist: [
				"下元水官大帝，全名稱下元三品五炁水官解厄金靈洞陰大帝暘谷帝君，簡稱下元三品水官解厄洞陰大帝，是道教中三官大帝之一，職掌江河湖海水域萬靈之事。傳說「水官解厄」，上解天災、下度生民，並考校眾生功過禍福，世人若改過遷善，就可消災解厄。可於水官大帝聖誕當天，進行補運或補財庫儀式，先祭煞解厄再祈福補運，達成趨吉避凶。",
			],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 41,
		year: 2,
		month: "十一月",
		date: "2023-11-30",
		applyName: " 《地母至尊娘娘聖誕》",
		content: {
			titleone: "2023-11-30（農曆10/18）",
			titleTwo: " 《地母至尊娘娘聖誕》",
			titleThree: "本宮祝壽大典時間：2023-11-29晚上23:00",
			titleFour: "地母至尊娘娘",
			contentlist: [
				"地母至尊娘娘，又稱「無極虛空地母至尊」，乃主宰大地山川之神、為道教四御之一、與主宰天界的玉皇上帝並列、自古即有『天陽地陰，天公地母』說法，而古人尊天親地也皆源自於人類對土地與自然的崇敬。",
			],
			url: "",
			imgurlList: [],
		},
	},
	{
		id: 42,
		year: 2,
		month: "十二月",
		date: "2023-12-27",
		applyName: " 《無極老母娘聖誕》",
		content: {
			titleone: "2023-12-27（農曆11/15）",
			titleTwo: " 《無極老母娘聖誕》",
			titleThree: "本宮祝壽大典時間： 2023-12-26晚上23:00",
			titleFour: "無極老母娘",
			contentlist: [
				"無極老母，又被尊稱為「無生老母」、「無極天母」，祂是自明清以來，許多民間興起的宗教，最主要的女神。 這些宗教包括了：羅教、無為教、大乘教、聞香教、天理教、一貫道等等。 在這些宗教中，都認為無極老母的起源，是從羅教的始祖羅祖，所寫的「五部六冊」著作中，“無生父母”的概念演變而來的。",
				"無極老母這個神既是造物主，創造了宇宙與人類，是所有生物的主宰，也是人類的祖先。同時，她還是救世主，拯救沉淪於苦海中的後代，派遣燃燈古佛、釋迦佛及彌勒佛下凡救世，也可以親自救度眾生；更可以在人死後判決人的轉世、超度，或者貶入地獄。",
			],
			url: "",
			imgurlList: [],
		},
	},
];
