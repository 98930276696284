<template>
	<div class="detail" v-if="!isMobile">
		<div class="center_content_plate"></div>

		<img src="../../assets/images/web/new_activity.png" alt="" />
		<div class="detailbox">
			<div class="titleTop">
				<div class="flexRow" style="cursor: pointer" @click="handleBack">
					<img src="../../assets/images/web/activity_goback.png" alt="" />
					<div class="font1">返回</div>
				</div>
				<div class="font2">共有 {{ srcList.length }}張 照片</div>
			</div>
			<div class="imgList">
				<el-image
					v-for="(item, index) in srcList"
					:key="index"
					class="el_image"
					:src="item"
					:zoom-rate="1.2"
					:initial-index="index"
					:preview-src-list="srcList"
					fit="cover"
					lazy
				/>
				<div style="height: 40px"></div>
			</div>
		</div>
	</div>
	<div class="detail_mobile" v-else>
		<img class="pc_imgback" src="../../assets/images/web/activity_pic-bg.png" />
		<div class="detailbox">
			<div class="titleTop" @click="handleBack">
				<div class="flexRow" style="cursor: pointer">
					<img src="../../assets/images/web/activity_goback.png" alt="" />
					<div class="font1" style="font-size: 20px">返回</div>
				</div>
				<div class="font2" style="font-size: 14px">共有 {{ srcList.length }}張 照片</div>
			</div>
			<div class="imgList" :style="{ height: clientHeight }">
				<el-image
					v-for="(item, index) in srcList"
					:key="index"
					class="el_image"
					:src="item"
					:zoom-rate="1.2"
					:initial-index="index"
					:preview-src-list="srcList"
					fit="cover"
					lazy
				/>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted } from "vue";
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
import { useRouter, useRoute } from "vue-router";
import { get, post } from "../../utils/request";
const router = useRouter();
const route = useRoute();
const srcList = ref([]);
const packagename = ref("");
const clientHeight = ref(0);
clientHeight.value = document.body.clientHeight - 266 + "px";
const handleBack = () => {
	router.go(-1);
};
onMounted(() => {
	if (route.query.type) {
		srcList.value = init();
		console.log(srcList.value);
	} else {
		getlist(route.query.id);
	}
});
const init = () => {
	// 死数据
	let list = [];
	let routequery = route.query;
	if (routequery.type == "2023special1") {
		packagename.value = "outtakes";
		for (let i = 1; i <= 20; i++) {
			list.push(
				require(`../../assets/images/web/outtakes/Color_small_0${
					i.toString().length < 2 ? "0" + Number(i) : Number(i)
				}.jpg`)
			);
		}
	} else if (routequery.type == "2023special2") {
		packagename.value = "outtakes2";
		for (let i = 1; i <= 13; i++) {
			list.push(
				require(`../../assets/images/web/outtakes2/Color_small_0${
					i.toString().length < 2 ? "0" + Number(i) : Number(i)
				}.jpg`)
			);
		}
	} else if (routequery.type == "2022special1") {
		packagename.value = "outtakes3";
		for (let i = 1; i <= 8; i++) {
			list.push(
				require(`../../assets/images/web/outtakes3/Color_small_0${
					i.toString().length < 2 ? "0" + Number(i) : Number(i)
				}.jpg`)
			);
		}
	} else if (routequery.type == "2022special2") {
		packagename.value = "outtakes4";
		for (let i = 1; i <= 48; i++) {
			list.push(
				require(`../../assets/images/web/outtakes4/Color_small_0${
					i.toString().length < 2 ? "0" + Number(i) : Number(i)
				}.jpg`)
			);
		}
	}
	return list;
};
const getlist = (e) => {
	get(`/web/gags/detail?id=${e}`).then((res) => {
		if (res.code == 1) {
			srcList.value = res.result.list;
			console.log(srcList.value);
		}
	});
};
</script>

<style lang="less" scoped>
&::-webkit-scrollbar {
	display: none;
}
/deep/ .el-image-viewer__prev,
/deep/ .el-image-viewer__next {
	color: #474693 !important;
	background-color: rgba(255, 255, 255, 0.8) !important;
}
/deep/ .el-image-viewer__close {
	background-color: rgba(255, 255, 255, 0) !important;
}
.flexRow {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.font1 {
	font-size: 24px;
	color: #ffffff;
	font-weight: 600;
	font-weight: bold;
}
.font2 {
	font-size: 16px;
	color: #ffffff;
	font-family: "NotoSerifTC-Regular";
}
.titleTop {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	position: relative;
	z-index: 2;
	img {
		width: 16px;
		height: 16px;
		margin-right: 8px;
	}
}

.detail {
	.detailbox {
		padding: 28px 59px 0px 59px;
		position: relative;
		z-index: 2;
		box-sizing: border-box;
		.imgList {
			height: calc(100vh - 285px);
			overflow: auto;
			.el_image {
				width: calc((100% - 72px) / 4);
				aspect-ratio: 1 / 0.64;
				margin-left: 24px;
				margin-top: 24px;
				border-radius: 8px;
				cursor: pointer;
			}

			.el_image:nth-child(4n + 1) {
				margin-left: 0px;
			}
			.el_image:nth-child(-n + 4) {
				margin-top: 15px;
			}
		}
	}
	box-sizing: border-box;
	overflow: hidden;
	> img {
		width: 100vw;
		position: absolute;
		bottom: 0px;
	}
}
.detail_mobile {
	.detailbox {
		padding: 0px 24px;
		.imgList {
			height: calc(100vh - 266px);
			padding-bottom: 20px;
			box-sizing: border-box;
			overflow: auto;
			.el_image {
				width: calc(100vw - 48px);
				height: calc((100vw - 48px) * 0.64);
				// aspect-ratio: 1 / 0.64;
				margin-top: 24px;
				border-radius: 8px;
				cursor: pointer;
			}
			.el_image:nth-child(1) {
				margin-top: 20px;
			}
		}
	}
}
.pc_imgback {
	width: 100vw;
	position: fixed;
	bottom: 70px;
	left: 0px;
}
/deep/ .el-image-viewer__canvas {
	img {
		max-width: 80% !important;
		max-height: 80% !important;
	}
}
</style>
