<template>
	<div class="shifuCome" v-if="!isMobile">
		<img src="../../assets/images/web/new_activity.png" alt="" />
		<div class="center_content_plate"></div>
		<div class="shifubox">
			<van-tabs
				v-model:active="active"
				background="none"
				color="#ffffff"
				line-width="15vw"
				line-height="2px"
				@click-tab="onClickTab"
			>
				<van-tab v-for="item in yearTabArray" :key="item.id" :title="item.name">
					<div class="imglist">
						<span
							class="imgrelative"
							v-for="ite in item.imgArray"
							:key="ite.id"
							@click="intoPhoto(ite.id, ite.year)"
						>
							<el-image class="el_image" :src="ite.coverImg" alt="" fit="cover" lazy />
							<div class="yearposition">
								{{ ite.gagsTime ? dayjs(ite.gagsTime).format("YYYY") : "" }}
							</div>
							<div class="yeardiscrip">{{ ite.gagsName ? ite.gagsName : "" }}</div>
						</span>
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
	<div class="shifuCome_mobile" v-else>
		<img class="pc_imgback" src="../../assets/images/web/activity_pic-bg.png" />
		<el-tabs v-model="active" class="demo-tabs" @tab-click="onClickTab">
			<el-tab-pane v-for="item in yearTabArray" :key="item.id" :label="item.name" :name="item.name">
				<div class="imglist" :style="{ height: clientHeight }">
					<div v-for="ite in item.imgArray" :key="ite.id" class="imgbox">
						<el-image class="el_image" :src="ite.coverImg" alt="" fit="cover" lazy />
						<div class="zhezhao" @click="intoPhoto(ite.id, ite.year)"></div>
						<div class="yearposition">
							{{ ite.gagsTime ? dayjs(ite.gagsTime).format("YYYY") : "" }}
						</div>
						<div class="yeardiscrip">{{ ite.gagsName ? ite.gagsName : "" }}</div>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { get, post } from "../../utils/request";
import dayjs from "dayjs";
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const active = ref("");
import { useRouter } from "vue-router";
const router = useRouter();
const yearTabArray = ref([]);
const oldTabArray = ref([
	{
		name: "2023 癸卯年",
		id: 2,
		year: 2023,
	},
	{
		name: "2022 壬寅年",
		id: 1,
		year: 2022,
	},
]);
onMounted(() => {
	init();
});
const init = () => {
	get("/web/gags/year/header").then((res) => {
		if (res.code === 1) {
			getUniqueValues(res.result);
		}
	});
};
const getlist = (e) => {
	get(`/web/gags/list?yearId=${e}`).then((res) => {
		if (res.code === 1) {
			yearTabArray.value.map((item) => {
				if (item.id == e) {
					if (item.id >= 3) {
						item.imgArray = res.result;
					} else if (item.id == 2) {
						item.imgArray = [
							...res.result,
							...[
								{
									id: 1,
									coverImg: require("../../assets/images/web/outtakes_2023_01.png"),
									year: "2023special1",
								},
								{
									id: 2,
									coverImg: require("../../assets/images/web/outtakes_2023_02.png"),
									year: "2023special2",
								},
							],
						];
					} else if (item.id == 1) {
						item.imgArray = [
							...res.result,
							...[
								{
									id: 1,
									coverImg: require("../../assets/images/web/outtakes_2022_01.png"),
									year: "2022special1",
								},
								{
									id: 2,
									coverImg: require("../../assets/images/web/outtakes_2022_02.png"),
									year: "2022special2",
								},
							],
						];
					}
				}
			});
		}
	});
};
const getUniqueValues = (list) => {
	let arr = [];
	list.map((item) => {
		if (item.id >= 3) {
			arr.push(item);
		}
	});
	yearTabArray.value = [...arr, ...oldTabArray.value];
	let array = yearTabArray.value;
	active.value = array[0].name;
	getlist(array[0].id);
};
const intoPhoto = (index, index2) => {
	router.push(`/outakesDetail?id=${index}&type=${index2 ? index2 : ""}`);
};
const clientHeight = ref(0);
clientHeight.value = document.body.clientHeight - 290 + "px";
const onClickTab = (e) => {
	let title = e.title || e.paneName;
	let list = yearTabArray.value.filter((item) => item.name == title);
	getlist(list[0].id);
};
</script>

<style lang="less" scoped>
&::-webkit-scrollbar {
	display: none;
}
.shifuCome {
	--van-tab-text-color: #ffffff;
	--van-tab-active-text-color: #ffffff;
	--van-tab-font-size: 24px;
	--van-tab-line-height: 40px;
	.shifubox {
		padding: 28px 59px 30px 59px;
	}
	box-sizing: border-box;
	overflow: hidden;
	> img {
		width: 100vw;
		position: absolute;
		bottom: 0px;
	}
	.imglist {
		margin-top: 10px;
		height: calc(100vh - 334px);
		overflow: auto;
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		.imgrelative {
			position: relative;
			width: calc((100% - 72px) / 4);
			height: calc(0.52 * (98vw - 72px) / 4);
			margin-left: 24px;
			margin-top: 24px;
			cursor: pointer;
			.yearposition {
				position: absolute;
				bottom: 31%;
				left: 5%;
				color: #fff;
				font-size: 1.1vw;
			}
			.yeardiscrip {
				position: absolute;
				bottom: 10%;
				left: 6%;
				color: #fff;
				font-size: 1.6vw;
				width: 80%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		.el-image {
			width: 100%;
			border-radius: 6px;
			height: 100%;
			object-fit: cover;
		}
		.imgrelative:nth-child(4n + 1) {
			margin-left: 0px;
		}
		.imgrelative:nth-child(-n + 4) {
			margin-top: 0px;
		}
	}
}
.demo-tabs {
	/deep/ .el-tabs__nav-wrap::after {
		height: 0px !important;
	}
	/deep/ .el-tabs__active-bar {
		background-color: #ffffff !important;
	}
	/deep/ .el-tabs__item {
		margin: 0 15px !important;
		padding: 8px 2px !important;
		color: #ffffff !important;
		font-size: 20px;
	}
	/deep/ .el-tabs__nav-prev,
	.el-tabs__nav-next {
		display: none !important;
	}
	/deep/ .el-tabs__nav-wrap.is-scrollable {
		padding: 0px !important;
	}
}
.shifuCome_mobile {
	.imgbox {
		position: relative;
	}
	.zhezhao {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 2;
		top: 0px;
	}
	.yearposition {
		position: absolute;
		bottom: 33%;
		left: 18px;
		color: #fff;
		font-size: 4.5vw;
	}
	.yeardiscrip {
		position: absolute;
		bottom: 12%;
		left: 18px;
		color: #fff;
		font-size: 7vw;
		width: 80%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.imglist {
		height: calc(100vh - 290px);
		overflow: auto;
		padding: 0px 24px 20px 24px;
		box-sizing: border-box;
		.imgbox {
			.el-image {
				width: 100%;
				height: calc(0.55 * (100vw - 40px));
				object-fit: cover;
				border-radius: 6px;
			}
			margin-top: 24px;
		}
		.imgbox:first-child {
			margin-top: 0px;
		}
	}
}
.pc_imgback {
	width: 100vw;
	position: fixed;
	bottom: 70px;
	left: 0px;
}
</style>
