import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import locale from 'element-plus/es/locale/lang/zh-cn';
import TopHeader from './components/common/TopHeader.vue';
import Footer from './components/common/Footer.vue';
import EventContent from './components/newEvents/EventContent.vue'
import { Dialog, showConfirmDialog, showToast, List, PullRefresh, Popup, DatePicker, Calendar, Radio, RadioGroup, Tab, Tabs } from 'vant';
import 'vant/lib/index.css';
import dayjs from 'dayjs';
import { limit } from './components/LimitClick';
import { isMobile } from './components/isMobile';

const app = createApp(App);
// 全局方法
app.config.globalProperties.$showDialog = showConfirmDialog;
app.config.globalProperties.$toast = showToast;
app.config.globalProperties.$date = dayjs;
app.config.globalProperties.$limit = limit;
app.config.globalProperties.$mobile = isMobile;

app.component("TopHeader", TopHeader);
app.component('Footer', Footer);
app.component('EventContent', EventContent);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(store);
app.use(router);
app.use(ElementPlus, { locale });
app.use(Dialog);
app.use(List);
app.use(PullRefresh);
app.use(Popup);
app.use(DatePicker);
app.use(Calendar);
app.use(Radio);
app.use(RadioGroup);
app.use(Tab);
app.use(Tabs);
app.mount("#app");
