<template>
	<div>
		<PcHome v-if="!typeIsMobile" />
		<MobileHome v-else />
	</div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
const { proxy } = getCurrentInstance();

const typeIsMobile = ref(false);

import PcHome from "../homeMain/pcHome.vue";
import MobileHome from "../homeMain/mobileHome.vue";

onMounted(() => {
	typeIsMobile.value = proxy.$mobile() ? true : false;
});
</script>
