<template>
	<img v-if="!typeIsMobile" class="pc_imgback" src="../assets/images/web/new_activity.png" alt="" />

	<img v-else class="pc_imgback" src="../assets/images/web/activity_pic-bg.png" alt="" />

	<div class="center_content_plate"></div>

	<div class="pc_center_plate center_flex_layout">
		<p class="empty_text">建置中，敬請期待</p>
	</div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
const { proxy } = getCurrentInstance();

const typeIsMobile = ref(false);

onMounted(() => {
	typeIsMobile.value = proxy.$mobile() ? true : false;

	let clientHeight = document.body.clientHeight;
	let centerHeight = document.getElementsByClassName("pc_center_plate")[0];

	if (typeIsMobile.value) {
		centerHeight.style.height = Math.floor(clientHeight - 236) + "px";
	} else {
		centerHeight.style.height = Math.floor(clientHeight - 222) + "px";
	}
});
</script>

<style lang="less" scoped>
.pc_imgback {
	width: 100vw;
	position: fixed;
	bottom: 0;

	@media screen and (max-width: 500px) {
		bottom: 70px;
	}
}

.center_content_plate {
	width: 100%;
	height: 100%;
	background: linear-gradient(to bottom, #b47e31 8%, #ffbe4e 28%, #ffefc7 70%);
	position: fixed;
	top: 0;
	opacity: 0.7;
}

.pc_center_plate {
	// height: calc(100vh - 226px);
	margin: 0 59px;
	position: relative;
	z-index: 2;

	@media screen and (max-width: 500px) {
		// height: calc(100vh - 236px);
		margin: 0 24px;
	}

	.empty_text {
		font-size: 24px;
		color: #ffffff;
		font-weight: bolder;
		// font-family: "Avenir";

		@media screen and (max-width: 500px) {
			font-size: 20px;
		}
	}
}
</style>
